import React, { ReactNode } from 'react'

export const ChartCard: React.FC<{
  title: string
  value?: string
  chart: ReactNode
  filter?: ReactNode
}> = ({ title, value = '', chart, filter }) => {
  return (
    <div
      className="
      p-8
      bg-light-gray-bg
      rounded-xl
        "
    >
      <div className="flex items-center justify-between gap-4 flex-wrap">
        <span
          className="
        font-recoleta-bold text-xl
        block mb-3
        "
        >
          {title}
        </span>

        {filter ? filter : undefined}
      </div>

      <span
        className="
        font-medium text-base
        block mb-9
        "
      >
        {value}
      </span>

      {chart}
    </div>
  )
}
