import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  Product,
  Section,
  SectionContent,
  Sections,
} from '../../../../types/types'
import Icon from '../../../../components/icon'
import { removeElementAtIndex } from '../../../../helpers'
import { ImageUploadPreviewModal } from '../../../../components/modal/app_modals/ImagePreviewModal'
import { SelectProductModal } from '../../../../components/modal/app_modals/selectProductModal'
import { Button } from '../../../../components/button'

const insertTextAtCursor = (
  text: string,
  textarea?: HTMLTextAreaElement | null,
) => {
  if (textarea) {
    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    const newValue =
      textarea.value.slice(0, start) + text + textarea.value.slice(end)

    return newValue
  }
}

export const ArticleSectionEditView = ({
  section,
  setActiveSectionIndex,
  sections,
  setSections,
  sectionIndex,
}: {
  section: Section
  setActiveSectionIndex: (index: number | null) => void
  sections: Sections
  setSections: (sections: Sections) => void
  sectionIndex: number
}) => {
  const [localSection, setLocalSection] = useState<Section>(section)
  const [allSectionsCopy, setAllSectionsCopy] = useState<Sections>(
    JSON.parse(JSON.stringify(sections)),
  )
  const [hasEmptyData, setHasEmptyData] = useState<boolean>(true)
  const [uploadedImage, setUploadedImage] = useState<File>()
  const [modal, setModal] = useState<string>('')
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [activeTextArea, setActiveArea] = useState<HTMLTextAreaElement | null>(
    null,
  )
  const [activeTextAreaIndex, setActiveAreaIndex] = useState<number>()

  const inputsRef = useRef<HTMLTextAreaElement[]>([])
  const imageRef = useRef<HTMLInputElement | null>(null)

  const buttonClassNames = `text-xs px-4 py-2 rounded border`

  const handleModalOpen = (name: string) => {
    setModal(name)
    setModalOpen(true)
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }

    const selectedFile = event.target.files[0]
    setUploadedImage(selectedFile)

    handleModalOpen('imageUploadPreviewModal')
  }

  useEffect(() => {
    const hasEmpty =
      localSection.content.some((item) =>
        ['', null, undefined].includes(item.value),
      ) || localSection.content.length === 0
    setHasEmptyData(hasEmpty)
  }, [localSection])

  useEffect(() => {
    setAllSectionsCopy(JSON.parse(JSON.stringify(sections)))
  }, [sections])

  return (
    <div
      className="
      flex flex-col gap-4 
      border border-divider-gray
      rounded-md p-6
      "
    >
      {/* Section content  */}
      {localSection?.content?.map((sectionContent, index) => {
        const key = `content-${index}-${sectionContent.type}`
        return (
          <div
            className="
            flex gap-6
          "
            key={key}
          >
            <div className="flex-1">
              {sectionContent.type === 'text' ? (
                <textarea
                  ref={(el) => el && (inputsRef.current[index] = el)}
                  onChange={(e) => {
                    const inputText = e.target.value
                    const localSectionCopy: Section = {
                      content: JSON.parse(JSON.stringify(localSection.content)),
                    }
                    localSectionCopy.content[index].value = inputText

                    // Remove products no longer referenced in the article text
                    const productPlaceholders: string[] =
                      inputText.match(/__product_[a-f0-9]+__/g) || []

                    localSectionCopy.content[index].products =
                      localSectionCopy.content[index].products.filter(
                        (product) =>
                          productPlaceholders.includes(
                            `__product_${product._id}__`,
                          ),
                      )

                    setLocalSection(localSectionCopy)
                  }}
                  value={sectionContent.value}
                  rows={10}
                  className="
                  w-full bg-gray-bg/60 p-4 rounded-lg
                  outline-none focus:outline-none
                  autofill:focus:bg-transparent autofill:bg-transparent
                  "
                ></textarea>
              ) : (
                <div className="p-4 rounded-md border border-black w-fit">
                  Image
                </div>
              )}
            </div>

            <div className="flex items-start gap-2">
              {sectionContent.type === 'text' && (
                <button
                  type="button"
                  onClick={() => {
                    setActiveArea(inputsRef.current[index])
                    setActiveAreaIndex(index)
                    handleModalOpen('selectProductModal')
                  }}
                  className="
                  bg-primary/10 
                  text-primary h-fit rounded-full p-2
                  "
                >
                  <Icon id="gifts-ion" width={16} height={16} className="" />
                </button>
              )}

              <button
                type="button"
                onClick={() => {
                  const localSectionCopy: Section = JSON.parse(
                    JSON.stringify(localSection),
                  )
                  const temp: SectionContent[] = removeElementAtIndex(
                    localSectionCopy.content,
                    index,
                  )
                  setLocalSection({
                    content: temp,
                  })
                }}
                className="bg-danger text-white rounded-full p-2 h-fit"
              >
                <Icon id="bin" width={16} height={16} className="" />
              </button>
            </div>
          </div>
        )
      })}

      {/* Section controls  */}
      <div className="mt-10 flex gap-4 items-center justify-between">
        <div className="flex items-center gap-2">
          <button
            type="button"
            onClick={() => {
              const localSectionCopy: Section = {
                content: [
                  ...JSON.parse(JSON.stringify(localSection.content)),
                  {
                    type: 'text',
                    value: '',
                    products: [],
                  },
                ],
              }
              setLocalSection(localSectionCopy)
            }}
            className="bg-sec-black text-white rounded-full p-2"
          >
            <Icon id="insert-text-icon" width={16} height={16} className="" />
          </button>

          <button
            type="button"
            onClick={() => {
              imageRef?.current?.click()
            }}
            className="bg-sec-black text-white rounded-full p-2"
          >
            <Icon id="insert-image-icon" width={16} height={16} className="" />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="!hidden"
              ref={imageRef}
            />
          </button>
        </div>

        <div className="flex items-center gap-2">
          <Button
            type="button"
            onClick={() => {
              setSections(allSectionsCopy)
              setActiveSectionIndex(null)
            }}
            label="Cancel"
            size="small"
          />

          <Button
            type="button"
            className={`${buttonClassNames} border-black bg-black text-white`}
            onClick={() => {
              allSectionsCopy[sectionIndex] = localSection
              setSections(allSectionsCopy)
              setActiveSectionIndex(null)
            }}
            disabled={hasEmptyData}
            primary
            label="Done"
            size="small"
          />
        </div>
      </div>

      {uploadedImage && (
        <ImageUploadPreviewModal
          image={uploadedImage}
          open={modal === 'imageUploadPreviewModal' && modalOpen}
          setOpen={setModalOpen}
          onCloseCallback={() => {
            setUploadedImage(undefined)
          }}
          onSuccessCallback={(url) => {
            const localSectionCopy: Section = {
              content: [
                ...JSON.parse(JSON.stringify(localSection.content)),
                {
                  type: 'image',
                  value: url,
                  products: [],
                },
              ],
            }
            setLocalSection(localSectionCopy)
          }}
        />
      )}

      <SelectProductModal
        open={modal === 'selectProductModal' && modalOpen}
        setOpen={setModalOpen}
        onCloseCallback={() => {}}
        onSuccessCallback={(data: Product) => {
          const updatedText =
            insertTextAtCursor(`__product_${data._id}__`, activeTextArea) ?? ''

          const localSectionCopy: Section = {
            content: JSON.parse(JSON.stringify(localSection.content)),
          }

          if (activeTextAreaIndex || activeTextAreaIndex === 0) {
            // set New value
            localSectionCopy.content[activeTextAreaIndex].value =
              updatedText ?? ''

            // Add new product to product list if product not already in list
            const existingProductIds = localSectionCopy.content[
              activeTextAreaIndex
            ].products.map((product) => product._id)

            if (!existingProductIds.includes(data._id)) {
              localSectionCopy.content[activeTextAreaIndex].products.push(data)
            }
          }

          setLocalSection(localSectionCopy)
          setModalOpen(false)
        }}
      />
    </div>
  )
}
