import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { useForgotPasswordMutation } from '../../../redux-toolkits/auth/auth.slice'
import { Link } from 'react-router-dom'
import { AuthWrapper } from '../../../components/wrapper/AuthWrapper'
import { TextInput } from '../../../components/inputs/textInput'
import { Button } from '../../../components/button'

const validate = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
})

const ForgotPasswordPage: React.FC = () => {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation()

  return (
    <div
      className="h-screen w-screen items-center flex justify-center bg-lighter}
    -blue"
    >
      <AuthWrapper header="Forgot password" className="w-full max-w-[420px]">
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validate}
          onSubmit={async (values) => {
            try {
              await forgotPassword(values)
            } catch (error) {
              console.log(error)
            }
          }}
        >
          {({ values, handleBlur, handleChange, errors, touched, isValid }) => (
            <Form className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <TextInput
                  type="text"
                  name="email"
                  value={values.email}
                  placeholder="Email address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasIcon={false}
                  inputClass=""
                  className=""
                  errors={touched.email && errors.email ? errors.email : ''}
                />

                <span>
                  Already have an account?{' '}
                  <Link to="/login" className="!inline font-normal">
                    Login
                  </Link>
                </span>
              </div>

              <Button
                type="submit"
                primary
                label="Proceed"
                disabled={!isValid || isLoading}
                loading={isLoading}
              />
            </Form>
          )}
        </Formik>
      </AuthWrapper>
    </div>
  )
}

export default ForgotPasswordPage
