import type { QueryProps, TransactionsResponse } from '../../types/types'
import { apiSlice } from '../api/apiSlice'

export const transactionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTransactions: builder.query<TransactionsResponse, QueryProps>({
      query: (args) => ({
        url: `/transactions?limit=${20}&skip=${
          args.page ? Number(args.page) * 20 - 20 : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetAllTransactionsQuery } = transactionsApiSlice
