import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { useLoginMutation } from '../../../redux-toolkits/auth/auth.slice'
import { type AuthResponse } from '../../../redux-toolkits/user/user.type'
import { useAppDispatch } from '../../../hooks'
import {
  setAuthUserData,
  signOut,
} from '../../../redux-toolkits/auth/auth.reducer'
import { Link, useNavigate } from 'react-router-dom'
import { AuthWrapper } from '../../../components/wrapper/AuthWrapper'
import { TextInput } from '../../../components/inputs/textInput'
import { Button } from '../../../components/button'

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Password is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
})

const LoginPage: React.FC = () => {
  const [login, { isLoading }] = useLoginMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <AuthWrapper header="Welcome back!" className="w-full max-w-[420px]">
      <Formik
        initialValues={{
          password: '',
          email: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={async (values) => {
          try {
            const res: any = await login(values).then((resp) => {
              if (resp.data?.success) {
                const userAuthData = resp.data.data as AuthResponse

                if (userAuthData.user?._id === undefined) {
                  dispatch(signOut())
                } else {
                  navigate('/dashboard')
                }
              }
            })
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {({ values, handleBlur, handleChange, errors, touched, isValid }) => (
          <Form className="flex flex-col gap-6">
            <TextInput
              type="text"
              name="email"
              value={values.email}
              placeholder="Email address"
              onChange={handleChange}
              onBlur={handleBlur}
              hasIcon={false}
              inputClass=""
              className=""
              errors={touched.email && errors.email ? errors.email : ''}
            />

            <div className="flex flex-col gap-2">
              <TextInput
                type="password"
                name="password"
                value={values.password}
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                hasIcon={false}
                inputClass=""
                className=""
                errors={
                  touched.password && errors.password ? errors.password : ''
                }
              />

              <Link to="/forgot-password" className="font-normal">
                Forgot password?
              </Link>
            </div>

            <Button
              type="submit"
              primary
              label="Sign In"
              disabled={!isValid || isLoading}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </AuthWrapper>
  )
}

export default LoginPage
