import React, { useState } from 'react'
import ModalComponent from '..'
import { Button } from '../../button'
import { useUploadImageMutation } from '../../../redux-toolkits/misc/misc.slice'

export const ImageUploadPreviewModal = ({
  image,
  open,
  setOpen,
  onCloseCallback,
  onSuccessCallback,
}: {
  image: File
  open: boolean
  setOpen: (val: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: string) => void
}) => {
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>(null)

  const [uploadImage, { isLoading }] = useUploadImageMutation()

  const handleUpload = () => {
    if (image) {
      const formData = new FormData()
      formData.append('image', image)

      uploadImage(formData)?.then((data) => {
        if (data?.data?.data) {
          onSuccessCallback && onSuccessCallback(data?.data?.data.url)
          setOpen(false)
          onCloseCallback && onCloseCallback()
        }
      })
    }
  }

  // Create a preview of the image
  const reader = new FileReader()
  reader.onloadend = () => {
    setImageSrc(reader.result)
  }
  if (image) {
    reader.readAsDataURL(image)
  }

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={onCloseCallback}
      title="Image Upload Preview"
    >
      <img
        src={imageSrc as string}
        className="
          w-full max-w-full h-fit object-contain
          rounded-md text-center
          "
        alt=""
      />

      <Button
        label="Upload Image"
        onClick={handleUpload}
        primary
        className="mt-20 w-full"
        loading={isLoading}
        disabled={isLoading}
      />
    </ModalComponent>
  )
}
