import { apiSlice } from '../api/apiSlice'
import type { ProductsResponse, QueryProps } from '../../types/types'
import {
  CreatedDiscoverItem,
  CreateSingleDiscoverResponse,
  DiscoverCardsResponse,
  EditSingleDiscoverItem,
  SingleDiscoverItem,
} from './discover.types'
import { toast } from 'react-toastify'
import { getErrorMessage } from '../../helpers'
import { ErrorType } from '../user/user.type'

export const discoverApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addDiscoverCard: builder.mutation<
      CreateSingleDiscoverResponse,
      SingleDiscoverItem
    >({
      query: (payload) => ({
        url: `/admin/discover-cards`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (data: CreateSingleDiscoverResponse) => {
        return data
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled
          if (res.data.success) {
            toast.success(res.data.message)
          }
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    editDiscoverCard: builder.mutation<
      CreateSingleDiscoverResponse,
      EditSingleDiscoverItem
    >({
      query: ({ id, payload }) => ({
        url: `/admin/discover-cards/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      transformResponse: (data: CreateSingleDiscoverResponse) => {
        return data
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled
          if (res.data.success) {
            toast.success(res.data.message)
          }
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    getDiscoverCards: builder.query<DiscoverCardsResponse, void>({
      query: (payload) => ({
        url: `/admin/discover-cards`,
        method: 'GET',
        body: payload,
      }),
      transformResponse: (data: DiscoverCardsResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
  }),
})

export const {
  useAddDiscoverCardMutation,
  useEditDiscoverCardMutation,
  useGetDiscoverCardsQuery,
} = discoverApiSlice
