import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { useResetPasswordMutation } from '../../../redux-toolkits/auth/auth.slice'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from '../../../components/button'
import { TextInput } from '../../../components/inputs/textInput'
import { AuthWrapper } from '../../../components/wrapper/AuthWrapper'

const validate = Yup.object().shape({
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Password is required'),
})

const ResetPasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams()

  const [resetPassword, { isLoading }] = useResetPasswordMutation()
  const navigate = useNavigate()
  const code = searchParams.get('code') ?? ''
  const email = searchParams.get('email') ?? ''

  return (
    <div
      className="h-screen w-screen items-center flex justify-center bg-lighter}
    -blue"
    >
      <AuthWrapper header="Reset password" className="w-full max-w-[420px]">
        <Formik
          initialValues={{
            password: '',
          }}
          validationSchema={validate}
          onSubmit={async (values) => {
            const payload = {
              email,
              code,
              password: values.password,
            }
            try {
              await resetPassword(payload)
              navigate('/login')
            } catch (error) {
              console.log(error)
            }
          }}
        >
          {({ values, handleBlur, handleChange, errors, touched, isValid }) => (
            <Form className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <TextInput
                  type="password"
                  name="password"
                  value={values.password}
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasIcon={false}
                  inputClass=""
                  className=""
                  errors={
                    touched.password && errors.password ? errors.password : ''
                  }
                />

                <span>
                  Already have an account?{' '}
                  <Link to="/login" className="!inline font-normal">
                    Login
                  </Link>
                </span>
              </div>

              <Button
                type="submit"
                primary
                label="Sign In"
                disabled={!isValid || isLoading}
                loading={isLoading}
              />
            </Form>
          )}
        </Formik>
      </AuthWrapper>
    </div>
  )
}

export default ResetPasswordPage
