import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'
import { BACKEND_BASE_URL, COOKIE_KEY } from '../../constants'
import type {
  VendorsResponse,
  QueryProps,
  InviteVendorPayload,
  InviteVendorResponse,
  VendorInvitesResponse,
  ResendInviteResponse,
} from '../../types/types'
import { toast } from 'react-toastify'
import { getErrorMessage } from '../../helpers'
import { ErrorType } from '../user/user.type'

export const vendorsApiSlice = createApi({
  reducerPath: 'vendorsApi',
  tagTypes: ['VENDORS'],
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_BASE_URL,
    prepareHeaders: (headers) => {
      const cookie = Cookies.get(COOKIE_KEY)!
      const token: string = cookie
      if (token && token !== 'undefined') {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getAllVendors: builder.query<VendorsResponse, QueryProps>({
      query: (params) => {
        return {
          url: `/vendors`,
          method: 'GET',
          params: {
            page: params.page ?? 1,
            limit: params.limit ?? 20,
          },
        }
      },
    }),
    getAllVendorInvites: builder.query<VendorInvitesResponse, QueryProps>({
      query: (params) => {
        return {
          url: `/vendors/invitations`,
          method: 'GET',
          params: {
            page: params.page ?? 1,
            limit: params.limit ?? 20,
          },
        }
      },
    }),
    inviteVendor: builder.mutation<InviteVendorResponse, InviteVendorPayload>({
      query: (payload) => ({
        url: `/vendors/invitations`,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          toast.success('Vendor invited successfully.')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    reInviteVendor: builder.mutation<ResendInviteResponse, { id: string }>({
      query: (payload) => ({
        url: `/vendors/invitations/${payload.id}/resend`,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const resp = await queryFulfilled
          toast.success(resp.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
  }),
})

export const {
  useGetAllVendorsQuery,
  useLazyGetAllVendorsQuery,
  useInviteVendorMutation,
  useGetAllVendorInvitesQuery,
  useReInviteVendorMutation,
} = vendorsApiSlice
