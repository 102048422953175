import React from 'react'
import Layout from '../../layout'
import { useGetUserQuery } from '../../redux-toolkits/user/user.slice'
import { useNavigate, useParams } from 'react-router'
import Icon from '../../components/icon'
import Spinner from '../../components/spinner/Spinner'

export const UserDetailPage: React.FC = () => {
  const navigate = useNavigate()
  const { userId } = useParams()

  const {
    data: user,
    isLoading,
    isError,
  } = useGetUserQuery({ id: Number(userId) })

  if (isLoading) {
    return <Spinner />
  } else if (isError) {
    return <p>Oops! something went wrong.</p>
  }

  return (
    <Layout>
      <button
        onClick={() => {
          navigate(-1)
        }}
        className="bg-white shadow-md p-2 rounded-full cursor-pointer hover:text-orange-400 mb-4"
      >
        <Icon id="back-arrow-icon" width={25} height={25} />
      </button>
      <div className="flex justify-between space-x-6">
        <div className="w-96 bg-white p-4 shadow-2xl rounded-lg space-y-6">
          <div>
            <label className="text-1xl">Full name</label>
            <p className="text-2xl">
              {user?.firstname} {user?.lastname}
            </p>
          </div>
          <div>
            <label className="text-1xl">Email Address</label>
            <p className="text-2xl">{user?.email}</p>
          </div>
          <div>
            <label className="text-1xl">Created At</label>
            <p className="text-2xl">{user?.createdAt}</p>
          </div>
          <div>
            <label className="text-1xl">Updated At</label>
            <p className="text-2xl">{user?.updatedAt}</p>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>
    </Layout>
  )
}
