import React, { useState } from 'react'
import Layout from '../../../layout'
import CuratedListsTable from './CuratedListsTable'
import clsx from 'clsx'
import Categories from '../../products/Categories'
import { PageHeader } from '../../../components/typography/PageHeader'

//"6679728135bf3f7f19f7d227",

export const CuratedListsPage: React.FC = () => {
  return (
    <Layout>
      <PageHeader title="Everyday Lists" />

      <CuratedListsTable />
    </Layout>
  )
}
