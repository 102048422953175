import React, { FC } from 'react'

export const HeaderSubheader: FC<{
  title: string
  text?: string
  className?: string
}> = ({ title, text, className }) => {
  return (
    <div className={`sm:flex-auto ${className}`}>
      <h1 className="text-xl font-recoleta-bold leading-6 text-black">
        {title}
      </h1>
      {text && (
        <p className="mt-[10px] text-sm font-normal text-sec-black">{text}</p>
      )}
    </div>
  )
}
