import React, { useState } from 'react'
import Layout from '../../layout'
import Spinner from '../../components/spinner/Spinner'
import {
  useGetOrdersQuery,
  useGetOrdersMetricsCountQuery,
  useGetOrdersMetricsValueQuery,
} from '../../redux-toolkits/orders/orders.slice'
import OrderTable from './OrderTable'
import {
  CURRENT_PAGE_NUMBER,
  NUMBER_OF_ITEMS_PER_PAGE,
  ORDERSTATUS,
} from '../../constants'
import CountCard from './CountCard'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Pagination from '../../components/pagination'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { PageHeader } from '../../components/typography/PageHeader'
import { TextInput } from '../../components/inputs/textInput'
import Dropdown from '../../components/inputs/dropdown'
import { Button } from '../../components/button'

export const OrdersPage: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const _startDate = searchParams.get('startDate') ?? ''
  const _endDate = searchParams.get('endDate') ?? ''
  const _status = searchParams.get('status') ?? ''
  const _skip = searchParams.get('skip') ?? CURRENT_PAGE_NUMBER

  const [status, setStatus] = useState<string>(_status)
  const [startDate, setStartDate] = useState<string>(_startDate)
  const [endDate, setEndDate] = useState<string>(_endDate)
  const [skip, setSkip] = useState<number | string>(_skip)

  const limit = searchParams.get('limit') ?? NUMBER_OF_ITEMS_PER_PAGE

  const { data: counts, isFetching: fetchingCountsMetrics } =
    useGetOrdersMetricsCountQuery({ startDate, endDate })
  const { data: values, isFetching: fetchingValuesMetrics } =
    useGetOrdersMetricsValueQuery({ startDate, endDate })

  const {
    data: payload,
    isFetching,
    isError,
    isSuccess,
  } = useGetOrdersQuery({
    skip: Number(skip),
    limit: Number(limit),
    status,
    startDate,
    endDate,
  })

  if (isError) {
    return <p>Oops! something went wrong.</p>
  }
  const value = values?.data
  const count = counts?.data

  const handlePageChange = (page: number): void => {
    const startD = _startDate !== '' ? `&startDate=${_startDate}` : ''
    const endD = _endDate !== '' ? `&endDate=${_endDate}` : ''
    const url = `/orders?status=${_status}&skip=${page}&limit=${limit}${startD}${endD}`

    navigate(url)
    setSkip(page)
  }

  return (
    <Layout>
      {fetchingCountsMetrics || fetchingValuesMetrics ? (
        <Spinner fullScreen={false} />
      ) : (
        <>
          <PageHeader
            title={`${
              status !== undefined && status !== ''
                ? status.toLowerCase()
                : 'All'
            } Orders`}
            className="capitalize"
          />

          <CountCardContainer>
            <CountCard
              count={value?.totalOrderValue ?? 0}
              text="Total order"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={value?.completedOrderValue ?? 0}
              text="Completed order"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={value?.pendingOrderValue ?? 0}
              text="Pending order"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={value?.processingOrderValue ?? 0}
              text="Processing order"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={value?.cancelledOrderValue ?? 0}
              text="Cancelled order"
              icon=""
              isCurrency={true}
            />

            <CountCard
              count={count?.totalOrderCount ?? 0}
              text="Total order count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={count?.pendingOrderCount ?? 0}
              text="Pending order count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={count?.processingOrderCount ?? 0}
              text="Processing order count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={count?.completedOrderCount ?? 0}
              text="Completed order count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={count?.cancelledOrderCount ?? 0}
              text="Cancelled order count"
              icon=""
              isCurrency={false}
            />
          </CountCardContainer>

          <div className="flex flex-wrap items-end justify-end gap-4 mb-10">
            <div className="flex flex-col gap-2">
              <p className="text-sm text-sec-black">Start Date:</p>
              <div>
                <TextInput
                  placeholder="Start date"
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value)
                    navigate(
                      `/orders?status=${_status}&skip=${skip}&limit=${limit}&startDate=${e.target.value}`,
                    )
                  }}
                  hasIcon={false}
                  readOnly={false}
                  className="!border-black !w-full md:max-w-fit"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-sm text-sec-black">End Date:</p>
              <div>
                <TextInput
                  placeholder="End date"
                  type="date"
                  name="endDate"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value)
                    navigate(
                      `/orders?status=${_status}&skip=${skip}&limit=${limit}&startDate=${_startDate}&endDate=${e.target.value}`,
                    )
                  }}
                  hasIcon={false}
                  readOnly={_startDate === '' || _startDate === undefined}
                  className="!border-black !w-full md:max-w-fit"
                />
              </div>
            </div>

            <Dropdown
              menuClassName=""
              menuButton={
                <Button
                  label={`Status: ${status}`}
                  primary={false}
                  icon="filter"
                  className="!py-4 !px-4 !text-base !text-light !w-full md:max-w-fit"
                />
              }
              onClickMenuItem={(selected) => {
                setStatus(selected.value)
                navigate(
                  `/orders?status=${
                    selected.value
                  }&skip=${skip}&limit=${limit}${
                    startDate !== '' ? `&startDate=${_startDate}` : ''
                  }${endDate !== '' ? `&endDate=${_endDate}` : ''}`,
                )
              }}
              menuItems={[
                { name: 'All', value: 'all' },
                ...ORDERSTATUS.map((status) => {
                  return {
                    name: status.name,
                    value: status.name,
                  }
                }),
              ]}
            />
          </div>

          <OrderTable
            orders={payload?.data?.orders ?? []}
            currentPage={Number(skip)}
            totalDataCount={payload?.data?.pagination?.count ?? 0}
            pageLimit={Number(limit)}
            onPageChange={handlePageChange}
            loading={isFetching}
          />
        </>
      )}
    </Layout>
  )
}
