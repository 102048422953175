import Autocomplete from 'react-google-autocomplete'
import React from 'react'
import { SelectedGoogleLocation } from '../../types/types'
import Icon from '../icon'

const AddressInput = ({
  onSelect,
  value,
  bgColor = 'bg-gray-bg',
  className,
}: {
  onSelect?: (address: SelectedGoogleLocation) => void
  value?: string
  bgColor?: string
  className?: string
}) => {
  return (
    <div
      className={`
        flex ${bgColor} 
        px-[17px] py-4
        items-center gap-3
        w-full rounded-[32px]
        focus-within:border text-gray-900 
        border-default-gray
        text-base sm:leading-6
        resize-none
        transition-all duration-300
        ${className}
        `}
    >
      <Icon id={'location'} width={22} height={22} />

      <Autocomplete
        defaultValue={value}
        apiKey={'AIzaSyDOWgytq4sghlYgVqrS-ab_SBDUTfB6Tbk'}
        onPlaceSelected={(place) => {
          const addressComponents = place?.address_components || []
          let city = ''
          let state = ''
          let country = ''

          addressComponents.forEach(
            (component: {
              long_name: string
              short_name: string
              types: string[]
            }) => {
              const types = component.types

              if (types.includes('locality')) {
                city = component.long_name
              }

              if (types.includes('administrative_area_level_1')) {
                state = component.long_name
              }

              if (types.includes('country')) {
                country = component.long_name
              }
            },
          )

          const selectedAddress = {
            address: place?.formatted_address || '',
            city,
            state,
            country,
            lat: place?.geometry?.location?.lat() || 0,
            lng: place?.geometry?.location?.lng() || 0,
            destinationGooglePlaceID: place?.place_id || '',
          }
          onSelect && onSelect(selectedAddress)
        }}
        debounce={300}
        options={{
          fields: [
            'address_components',
            'geometry.location',
            'formatted_address',
            'place_id',
          ],
          types: ['cities'],
          componentRestrictions: { country: 'ng' },
        }}
        className={`
            border-transparent ${bgColor} 
            w-full placeholder:text-gray-400 
            !ring-0 !ring-transparent
            !outline-none !outline-0
            focus:border-transparent
            `}
      />
    </div>
  )
}

export default AddressInput
