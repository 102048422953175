import React, { useState } from 'react'
import Layout from '../../layout'
import {
  useGetAllVendorInvitesQuery,
  useGetAllVendorsQuery,
  useInviteVendorMutation,
  useReInviteVendorMutation,
} from '../../redux-toolkits/vendors/vendors.slice'
import ReactPaginate from 'react-paginate'
import Spinner, { LoadingOval } from '../../components/spinner/Spinner'
import { Plus } from 'lucide-react'
import CountCard from '../orders/CountCard'
import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import * as Yup from 'yup'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import { Vendor } from '../../types/types'
import ModalComponent from '../../components/modal'
import { TextInput } from '../../components/inputs/textInput'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { format } from 'date-fns'

const PendingVendors: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)
  const { data, isFetching } = useGetAllVendorInvitesQuery({
    page: `${page}`,
    limit: String(NUMBER_OF_ITEMS_PER_PAGE),
  })

  const [resendInvitation, { isLoading: resendingInvitation }] =
    useReInviteVendorMutation()

  return (
    <>
      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total Vendors"
          count={data?.data?.total ? data?.data?.total : 0}
        />
      </CountCardContainer>

      <TableComponent
        headers={['Name', 'Email', 'Invitation date', 'Expiration date', '']}
        rows={
          data?.data.docs.map((vendor) => {
            return {
              id: vendor._id,
              content: [
                vendor.name,
                vendor.email,
                format(new Date(vendor.createdAt), 'yyyy-MM-dd'),
                format(new Date(vendor.expiresAt), 'yyyy-MM-dd'),
                <Dropdown
                  key={`${vendor._id}-controls`}
                  menuButton={
                    <Icon id="ellipses" height={18} width={18} className="" />
                  }
                  onClickMenuItem={() => {}}
                  menuItems={[
                    {
                      name: (
                        <button
                          className="disabled:opacity-30 w-full text-left flex items-center justify-between"
                          onClick={(e): any => {
                            e.stopPropagation()
                            resendInvitation({ id: vendor._id })
                          }}
                          disabled={resendingInvitation}
                        >
                          Resend invitation
                          {resendingInvitation && (
                            <LoadingOval
                              loaderWidth="16"
                              loaderHeight="16"
                              color="black"
                            />
                          )}
                        </button>
                      ),
                      value: '',
                    },
                  ]}
                />,
              ],
            }
          }) ?? []
        }
        name="vendors"
        loading={isFetching}
        isEmpty={!data?.data || data.data.docs.length < 1}
        totalDataCount={data?.data.total}
        currentPage={page}
        pageLimit={NUMBER_OF_ITEMS_PER_PAGE}
        onPageChange={(page) => {
          setPage(page)
        }}
      />
    </>
  )
}

export default PendingVendors
