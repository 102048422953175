import React, { useEffect } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  AddDeliveryLocation,
  CreateCategoryPayload,
  DeliveryTimeType,
  Tag,
} from '../../../redux-toolkits/products/products.type'
import {
  AddComboItemSchema,
  AddDeliveryLocationSchema,
  CreateCategorySchema,
  CreateTagSchema,
} from '../../../features/users/validate'
import {
  useCreateProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductCategoryMutation,
  useEditProductTagMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import { ComboItem, DeliveryLocation } from '../../../types/types'
import { Checkbox } from '../../inputs/Checkbox'
import Icon from '../../icon'
import Dropdown from '../../inputs/dropdown'
import { useLazyFetchAvailableLocationsQuery } from '../../../redux-toolkits/user/user.slice'

const deliveryTypeOptions = [
  {
    name: 'Minutes',
    value: 'minutes',
  },
  {
    name: 'Days',
    value: 'days',
  },
]

export const AddDeliveryLocationModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: AddDeliveryLocation) => void
  mode: 'edit' | 'create'
  location?: AddDeliveryLocation
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = (val) => {},
  mode,
  location,
}) => {
  const [fetchLocations, { isFetching, data: locationsData }] =
    useLazyFetchAvailableLocationsQuery()

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
    initialValues,
  } = useFormik({
    initialValues: location
      ? {
          locationId: location.locationId,
          locationName: location.locationName,
          minimumDeliveryTime: location.minimumDeliveryTime,
          maximumDeliveryTime: location.maximumDeliveryTime,
          type: location.type,
        }
      : {
          locationId: '',
          locationName: '',
          minimumDeliveryTime: 0,
          maximumDeliveryTime: 0,
          type: 'minutes',
        },
    enableReinitialize: true,
    validationSchema: AddDeliveryLocationSchema,
    onSubmit: (values, { resetForm }) => {
      const itemToAdd: AddDeliveryLocation = {
        locationId: values.locationId,
        locationName: values.locationName,
        minimumDeliveryTime: values.minimumDeliveryTime,
        maximumDeliveryTime: values.maximumDeliveryTime,
        type: values.type as DeliveryTimeType,
      }

      onSuccessCallback(itemToAdd)
      resetForm()
    },
  })

  useEffect(() => {
    open && fetchLocations()
    console.log(values, errors)
  }, [open, values])

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={mode === 'create' ? 'Add new location' : 'Edit location'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <Dropdown
          loading={isFetching}
          className=""
          menuClassName="max-h-[200px]"
          widthClass="!w-full"
          menuButton={
            <TextInput
              type="text"
              name="type"
              placeholder="Delivers to"
              value={values.locationName}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={
                errors.locationId && touched.locationId ? errors.locationId : ''
              }
              hasIcon={false}
              readOnly
              extra={
                <Icon
                  id={'left-caret'}
                  width={16}
                  height={16}
                  className="-rotate-90 text-sec-black"
                />
              }
              className="!border-divider-gray"
            />
          }
          onClickMenuItem={(selection) => {
            setFieldValue('locationId', selection.value)
            setFieldValue('locationName', selection.name)
          }}
          menuItems={locationsData?.data.docs.map((loc) => {
            return {
              name: loc.name,
              value: loc._id,
            }
          })}
        />

        <TextInput
          type="text"
          name="minimumDeliveryTime"
          placeholder="Min delivery time"
          value={String(values.minimumDeliveryTime)}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.minimumDeliveryTime && touched.minimumDeliveryTime
              ? errors.minimumDeliveryTime
              : ''
          }
          hasIcon={false}
        />

        <TextInput
          type="text"
          name="maximumDeliveryTime"
          placeholder="Max delivery time"
          value={String(values.maximumDeliveryTime)}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.maximumDeliveryTime && touched.maximumDeliveryTime
              ? errors.maximumDeliveryTime
              : ''
          }
          hasIcon={false}
        />

        <Dropdown
          loading={false}
          className=""
          menuClassName="max-h-[200px]"
          widthClass="!w-full"
          menuButton={
            <TextInput
              type="text"
              name="type"
              placeholder="Delivers in"
              value={values.type}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.type && touched.type ? errors.type : ''}
              hasIcon={false}
              readOnly
              extra={
                <Icon
                  id={'left-caret'}
                  width={16}
                  height={16}
                  className="-rotate-90 text-sec-black"
                />
              }
              className="!border-divider-gray"
            />
          }
          onClickMenuItem={(selection) => {
            setFieldValue('type', selection.value)
          }}
          menuItems={deliveryTypeOptions}
        />

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid}
          loading={false}
          primary
        />
      </form>
    </ModalComponent>
  )
}
