import React, { useState } from 'react'
import { format } from 'date-fns'
import { Avatar } from '../../components/avatar'
import { formatCurrency } from '../../helpers'
import { type OrderResponse } from '../../redux-toolkits/orders/orders.type'
import {
  DISABLE__DELIVERY_REQUEST__FROM,
  ORDERSTATUS,
  PRIMARY_COLOR,
} from '../../constants'
import {
  useRequestOrderDeliveryMutation,
  useUpdateOrderMutation,
} from '../../redux-toolkits/orders/orders.slice'
import { Link } from 'react-router-dom'
import { TableComponent } from '../../components/table'
import { Status } from '../../components/cards/statusTag'
import { StatusTypes } from '../../types/types'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { LoadingOval } from '../../components/spinner/Spinner'

interface Props {
  orders: OrderResponse[]
  currentPage?: number
  totalDataCount?: number
  pageLimit?: number
  onPageChange?: (page: number) => void
  loading?: boolean
}

const OrderTable: React.FC<Props> = ({
  orders,
  currentPage,
  totalDataCount,
  pageLimit,
  onPageChange,
  loading,
}): JSX.Element => {
  const [updateOrder, { isLoading: updatingOrder }] = useUpdateOrderMutation()

  const [isEditOrder, setIsEditOrder] = useState<boolean>(false)
  const [orderId, setOrderId] = useState<string>('')

  const handleChangeStatus = async (
    id: string,
    status: string,
  ): Promise<void> => {
    try {
      await updateOrder({ id: id, body: { status: status } })
      setIsEditOrder(false)
    } catch (error) {
      console.log(error)
    }
  }

  const [requestDelivery, { isLoading }] = useRequestOrderDeliveryMutation()

  const handleRequestDelivery = async (id: string): Promise<void> => {
    await requestDelivery({ id })
  }

  return (
    <div className="">
      <TableComponent
        name="Orders"
        showName
        headers={[
          'Order number',
          'Customer',
          'Date',
          'Amount',
          'Status',
          <div className="w-full flex justify-end" key={`header-controls`}>
            {isLoading || updatingOrder ? (
              <LoadingOval
                loaderHeight="25"
                loaderWidth="25"
                color={PRIMARY_COLOR}
              />
            ) : (
              ''
            )}
          </div>,
        ]}
        rows={orders.map((order) => {
          return {
            id: order.order._id,
            content: [
              <Link
                to={`/orders/${order.order._id}`}
                className="text-primary"
                key={`${order.order._id}-product-id`}
              >
                #{order.order.orderNumber}
              </Link>,
              <div
                className="flex items-center space-x-3"
                key={`${order.order._id}-customer`}
              >
                <Avatar
                  name={`${order.order.personalInformation.firstName} ${order.order.personalInformation.lastName}`}
                />
                <span>
                  {`${order.order.personalInformation.firstName} ${order.order.personalInformation.lastName}`}
                </span>
              </div>,
              `${format(order.order.createdAt, 'yyyy-MM-dd')}`,
              `${formatCurrency(order.order.amount)}`,
              <div
                key={`${order.order._id}-status`}
                className="flex items-center gap-2 overflow-visible"
              >
                <Status
                  text={order.order.status}
                  type={
                    (ORDERSTATUS.find(
                      (status) =>
                        status.name.toLowerCase() ===
                        order.order.status.toLowerCase(),
                    )?.type ?? 'warn') as StatusTypes
                  }
                />

                {!updatingOrder && (
                  <Dropdown
                    menuClassName="max-h-[170px]"
                    menuButton={
                      <div className="bg-primary/5 text-primary rounded-full p-2">
                        <Icon
                          id="left-caret"
                          height={10}
                          width={10}
                          className="-rotate-90"
                        />
                      </div>
                    }
                    onClickMenuItem={(selected) => {
                      setOrderId(order.order._id)
                      handleChangeStatus(order.order._id, selected.value)
                    }}
                    menuItems={ORDERSTATUS.map((status) => {
                      return {
                        name: status.name,
                        value: status.name,
                      }
                    })}
                  />
                )}
              </div>,
              <Dropdown
                key={`${order.order._id}-controls`}
                menuButton={
                  <Icon id="ellipses" height={18} width={18} className="" />
                }
                onClickMenuItem={() => {}}
                menuItems={[
                  {
                    name: (
                      <button
                        className="
                        disabled:opacity-30
                        disabled:cursor-not-allowed
                        w-full text-left
                        "
                        onClick={(): any =>
                          handleRequestDelivery(order.order._id)
                        }
                        disabled={
                          isLoading ||
                          (ORDERSTATUS.find(
                            (stat) =>
                              stat.name.toLowerCase() ===
                              order.order.status.toLowerCase(),
                          )?.id ?? Infinity) >= DISABLE__DELIVERY_REQUEST__FROM
                        }
                      >
                        Request delivery
                      </button>
                    ),
                    value: '',
                  },
                ]}
              />,
            ],
          }
        })}
        loading={loading}
        currentPage={currentPage}
        totalDataCount={totalDataCount}
        pageLimit={pageLimit}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default React.memo(OrderTable)
