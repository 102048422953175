import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from '../hooks'
import { authSelector } from '../redux-toolkits/auth/auth.selector'

const ProtectedRoute = (): JSX.Element => {
  const { signedIn } = useAppSelector(authSelector)

  return (signedIn as boolean) ? <Outlet /> : <Navigate to="/login" replace />
}

export default ProtectedRoute
