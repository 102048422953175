import React, { ChangeEvent, useRef } from 'react'
import Icon from '../icon'

export const ImageUploadButton: React.FC<{
  showText?: boolean
  className?: string
  handleChange: (val: ChangeEvent<HTMLInputElement>) => void
}> = ({ showText = true, handleChange, className = '' }) => {
  const imageRef = useRef<HTMLInputElement | null>(null)

  return (
    <button
      type="button"
      onClick={() => {
        imageRef?.current?.click()
      }}
      className={`
            bg-primary/5 text-primary
            absolute top-4 right-4
            py-2 px-4 rounded-full 
            border border-black border-dashed
            flex items-center gap-2
            text-xs
            ${className}
            `}
    >
      <Icon id="camera" width={20} height={20} />
      {showText && <span className="text-black">Click to upload image</span>}
      <input
        type="file"
        accept="image/*"
        onChange={handleChange}
        className="!hidden"
        ref={imageRef}
      />
    </button>
  )
}
