import { apiSlice } from '../api/apiSlice'
import type {
  CreateVariantResponse,
  ProductsResponse,
  QueryProps,
  SingleProductResponse,
  VariantsResponse,
} from '../../types/types'
import {
  CreateCategoryPayload,
  CreateCategoryResponse,
  CreateDiscount,
  CreateDiscountSuccess,
  CreateProductPayload,
  CreateProductResponse,
  CreateTagPayload,
  CreateTagResponse,
  DiscountsResponse,
  TagsResponse,
  UpdateProductStockPayload,
} from './products.type'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { toast } from 'react-toastify'
import { getErrorMessage } from '../../helpers'
import { ErrorType } from '../user/user.type'

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query<ProductsResponse, QueryProps>({
      query: (args) => {
        const limit = Number(args.limit) ?? NUMBER_OF_ITEMS_PER_PAGE
        return {
          url: `/items?limit=${limit}&skip=${
            args.page ? Number(args.page) * limit - limit : ''
          }${
            args.search && args.search !== '' ? `&search=${args.search}` : ''
          }`,
          method: 'GET',
        }
      },
    }),
    getSingleProduct: builder.query<SingleProductResponse, { id: string }>({
      query: (args) => {
        return {
          url: `/items/${args.id}`,
          method: 'GET',
        }
      },
    }),
    getAllProductTags: builder.query<TagsResponse, QueryProps>({
      query: (args) => ({
        url: `/items/tags?limit=${args.limit}&skip=${args.skip}`,
        method: 'GET',
      }),
    }),
    createProductCategory: builder.mutation<
      CreateCategoryResponse,
      CreateCategoryPayload
    >({
      query: (payload) => ({
        url: `/items/categories`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (data: CreateCategoryResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    editProductCategory: builder.mutation<
      CreateCategoryResponse,
      { id: string; payload: CreateCategoryPayload }
    >({
      query: (payload) => ({
        url: `/items/categories/${payload.id}`,
        method: 'PUT',
        body: payload.payload,
      }),
      transformResponse: (data: CreateCategoryResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    createProductTag: builder.mutation<CreateTagResponse, CreateTagPayload>({
      query: (payload) => ({
        url: `/items/tags`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (data: CreateTagResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    editProductTag: builder.mutation<
      CreateTagResponse,
      { id: string; payload: CreateTagPayload }
    >({
      query: (payload) => ({
        url: `/items/tags/${payload.id}`,
        method: 'PUT',
        body: payload.payload,
      }),
      transformResponse: (data: CreateTagResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),

    getVariants: builder.query<VariantsResponse, QueryProps>({
      query: (args) => {
        const limit = Number(args.limit) ?? NUMBER_OF_ITEMS_PER_PAGE
        return {
          url: `/items/variants?limit=${limit}&skip=${
            args.page ? Number(args.page) * limit - limit : ''
          }${
            args.search && args.search !== '' ? `&search=${args.search}` : ''
          }`,
          method: 'GET',
        }
      },
    }),

    createVariant: builder.mutation<CreateVariantResponse, { name: string }>({
      query: (payload) => ({
        url: `/items/variants`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (data: CreateVariantResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),

    createProduct: builder.mutation<
      CreateProductResponse,
      CreateProductPayload
    >({
      query: (payload) => ({
        url: `/items/create`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (data: CreateProductResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),

    EditProduct: builder.mutation<
      CreateProductResponse,
      { id: string; body: CreateProductPayload }
    >({
      query: (payload) => ({
        url: `/items/${payload.id}`,
        method: 'PUT',
        body: payload.body,
      }),
      transformResponse: (data: CreateProductResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),

    updateItemStock: builder.mutation<
      { success: boolean; message: string; data: null },
      { id: string; payload: UpdateProductStockPayload }
    >({
      query: (payload) => ({
        url: `items/${payload.id}/stock`,
        method: 'PUT',
        body: payload.payload,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),

    createDiscount: builder.mutation<CreateDiscountSuccess, CreateDiscount>({
      query: (payload) => ({
        url: `/discount`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (data: CreateDiscountSuccess) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),

    editDiscount: builder.mutation<
      CreateDiscountSuccess,
      { id: string; data: CreateDiscount }
    >({
      query: (payload) => ({
        url: `/discount/${payload.id}`,
        method: 'PUT',
        body: payload.data,
      }),
      transformResponse: (data: CreateDiscountSuccess) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),

    getDiscounts: builder.query<DiscountsResponse, QueryProps>({
      query: (args) => {
        const limit = Number(args.limit) ?? NUMBER_OF_ITEMS_PER_PAGE
        return {
          url: `/discount?limit=${limit}&skip=${
            args.page ? Number(args.page) * limit - limit : '0'
          }${
            args.search && args.search !== '' ? `&search=${args.search}` : ''
          }`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const {
  useGetAllProductsQuery,
  useLazyGetAllProductsQuery,
  useGetAllProductTagsQuery,
  useLazyGetAllProductTagsQuery,
  useCreateProductCategoryMutation,
  useEditProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductTagMutation,
  useLazyGetSingleProductQuery,
  useGetVariantsQuery,
  useCreateVariantMutation,
  useCreateProductMutation,
  useEditProductMutation,
  useUpdateItemStockMutation,
  useCreateDiscountMutation,
  useEditDiscountMutation,
  useGetDiscountsQuery,
} = productsApiSlice
