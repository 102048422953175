import { ErrorMessage, Field, Form } from 'formik'
import React from 'react'

interface Props {
  isLoading: boolean
  clearUserData?: () => void
}

const CustomForm: React.FC<Props> = ({ isLoading }) => {
  return (
    <div className="bg-white p-4 rounded-lg">
      <Form>
        <div className="mt-2 p-2">
          <label htmlFor="firstname">First Name</label>
          <Field
            name="firstname"
            placeholder="First Name"
            type="text"
            className="shadow-md h-14 w-full border-2 text-xl px-2 outline-none"
          />
          <span className="text-xs text-red-600">
            <ErrorMessage name="firstname" />
          </span>
        </div>
        <div className="mt-2 p-2">
          <label htmlFor="lastname">Last Name</label>
          <Field
            name="lastname"
            placeholder="Last name"
            type="text"
            className="shadow-md h-14 w-full border-2 text-xl px-2 outline-none"
          />
          <span className="text-xs text-red-600">
            <ErrorMessage name="lastname" />
          </span>
        </div>
        <div className="mt-2 p-2">
          <label htmlFor="email">Email</label>
          <Field
            name="email"
            placeholder="Email"
            type="email"
            className="shadow-md h-14 w-full border-2 text-xl px-2 outline-none"
          />
          <span className="text-xs text-red-600">
            <ErrorMessage name="email" />
          </span>
        </div>
        <div className="mt-2 p-2">
          <label htmlFor="password">Password</label>
          <Field
            name="password"
            placeholder="Password"
            type="password"
            className="shadow-md h-14 w-full border-2 text-xl px-2 outline-none"
          />
          <span className="text-xs text-red-600">
            <ErrorMessage name="password" />
          </span>
        </div>

        <div className="mt-10 mb-4">
          <button
            type="submit"
            disabled={isLoading}
            className="bg-light-blue uppercase p-4 text-sm text-white shadow-2xl rounded-full font-bold w-full"
          >
            {isLoading ? 'loading' : 'Save'}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default CustomForm
