import React, { useState } from 'react'
import {
  useCreatePermissionMutation,
  useEditPermissionMutation,
  useGetPermissionsQuery,
} from '../../redux-toolkits/user/user.slice'
import { useAppSelector } from '../../hooks'
import { userSelector } from '../../redux-toolkits/user/user.selector'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { type Permission } from '../../redux-toolkits/user/user.type'
import Spinner from '../../components/spinner/Spinner'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import { Button } from '../../components/button'
import { TableComponent } from '../../components/table'
import { Status } from '../../components/cards/statusTag'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { TextInput } from '../../components/inputs/textInput'
import ModalComponent from '../../components/modal'

const PremissionsSection: React.FC = () => {
  const [editing, setEditing] = useState<Permission | undefined>(undefined)
  const [openModal, setOpenModal] = useState(false)

  const { isLoading } = useGetPermissionsQuery({
    skip: 0,
    limit: 100,
  })

  const { permissions } = useAppSelector(userSelector)

  return (
    <div>
      <div className="flex flex-col gap-10">
        <div className="flex items-center">
          <HeaderSubheader
            title="Permissions"
            text="Here you can create and manage permissions for your team members."
          />

          <Button
            type="button"
            label="Create new permission"
            primary
            size="medium"
            onClick={() => {
              setOpenModal(true)
            }}
            icon="plus"
          />
        </div>

        <TableComponent
          loading={isLoading}
          headers={['Name', 'Description', 'Status', 'Code', ' ']}
          rows={permissions.map((permission) => {
            return {
              id: permission._id,
              content: [
                permission.name,
                permission.description,
                <Status
                  key={`${permission._id}-status`}
                  text={!permission.active ? 'Inactive' : 'Active'}
                  type={!permission.active ? 'fail' : 'success'}
                />,
                permission.code,
                <Dropdown
                  className="text-left"
                  key={`${permission._id}-controls`}
                  menuButton={
                    <Icon id="ellipses" height={18} width={18} className="" />
                  }
                  onClickMenuItem={() => {}}
                  menuItems={[
                    {
                      name: (
                        <button
                          onClick={() => {
                            setEditing(permission)
                            setOpenModal(true)
                          }}
                          className="w-full text-left"
                        >
                          Edit
                        </button>
                      ),
                      value: '',
                    },
                  ]}
                />,
              ],
            }
          })}
        />
      </div>
      <CreatePermissionModal
        permission={editing}
        open={openModal}
        setOpen={setOpenModal}
      />
    </div>
  )
}

const CreatePermissionModal: React.FC<{
  open: boolean
  setOpen: (open: boolean) => void
  permission?: Permission
}> = ({ open, setOpen, permission }) => {
  const [createPermission, { isLoading: creatingPermission }] =
    useCreatePermissionMutation()
  const [editPermission, { isLoading: updatingPermission }] =
    useEditPermissionMutation()

  const closeModal = (): void => {
    setOpen(false)
  }

  return (
    <>
      <ModalComponent
        title={`${permission !== undefined ? 'Edit' : 'Create new'} permission`}
        open={open}
        setOpen={() => {}}
        onCloseCallback={() => {
          closeModal()
        }}
      >
        <Formik
          initialValues={{
            name: permission?.name ?? '',
            description: permission?.description ?? '',
          }}
          onSubmit={async (values) => {
            if (permission !== null && permission !== undefined) {
              await editPermission({ id: permission._id, payload: values })
            } else {
              await createPermission(values)
            }
            closeModal()
          }}
        >
          {({ values, handleBlur, handleChange, errors, touched }) => (
            <Form className="flex flex-col gap-4">
              <TextInput
                type="text"
                name="name"
                placeholder="Permission name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.name && touched.name ? errors.name : ''}
                hasIcon={false}
              />

              <TextInput
                type="textarea"
                name="description"
                placeholder="Permission description"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.description && touched.description
                    ? errors.description
                    : ''
                }
                hasIcon={false}
              />

              <Button
                disabled={creatingPermission || updatingPermission}
                loading={creatingPermission || updatingPermission}
                type="submit"
                label="Proceed"
                primary
                className="!mt-6 !w-full"
              />
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  )
}

export default PremissionsSection
