import React from 'react'
import './PageNotFound.css'

export const PageNotFound: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <p>Oops! this page does not exist</p>
    </div>
  )
}
