import React, { useState } from 'react'
import Layout from '../../layout'
import {
  useGetAllVendorsQuery,
  useInviteVendorMutation,
} from '../../redux-toolkits/vendors/vendors.slice'
import ReactPaginate from 'react-paginate'
import Spinner from '../../components/spinner/Spinner'
import { Plus } from 'lucide-react'
import CountCard from '../orders/CountCard'
import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import * as Yup from 'yup'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import { Vendor } from '../../types/types'
import ModalComponent from '../../components/modal'
import { TextInput } from '../../components/inputs/textInput'

const ActiveVendors: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)
  const { data, isFetching } = useGetAllVendorsQuery({
    page: `${page}`,
    limit: '20',
  })

  const onChangePage = (page: number): void => {
    setPage(page + 1)
  }

  return (
    <>
      <div className="flex justify-end">
        <Button
          label="Invite vendor"
          onClick={() => setOpenModal(true)}
          primary
          icon="plus"
          size="medium"
        />
      </div>

      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total Vendors"
          count={data?.data?.total ? data?.data?.total : 0}
        />
      </CountCardContainer>

      <TableComponent
        headers={['Name', 'Email', 'Phone', 'Address']}
        rows={
          data?.data.docs.map((vendor) => {
            return {
              id: vendor._id,
              content: [
                vendor.name,
                vendor.email,
                vendor.phone,
                typeof vendor.address === 'string'
                  ? vendor.address
                  : vendor.address.address,
              ],
            }
          }) ?? []
        }
        name="vendors"
        loading={isFetching}
        isEmpty={!data?.data || data.data.docs.length < 1}
        totalDataCount={data?.data.total}
        currentPage={page}
        pageLimit={NUMBER_OF_ITEMS_PER_PAGE}
        onPageChange={(page) => {
          setPage(page)
        }}
      />

      <InviteVendorModal
        open={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </>
  )
}

const InviteVendorModal: React.FC<{
  open: boolean
  closeModal: () => void
  vendor?: Vendor
}> = ({ open, closeModal, vendor }) => {
  const [inviteVendor, { isLoading: invitingVendor }] =
    useInviteVendorMutation()

  return (
    <ModalComponent
      title={'Invite Vendor'}
      open={open}
      setOpen={() => {}}
      onCloseCallback={() => {
        closeModal()
      }}
    >
      <Formik
        initialValues={{
          name: vendor?.name ?? '',
          email: vendor?.email ?? '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        })}
        onSubmit={async (values) => {
          await inviteVendor(values).then((resp) => {
            if (resp.data?.success) {
              closeModal()
            }
          })
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form className="flex flex-col gap-4">
            <TextInput
              name="name"
              placeholder="Vendor Name"
              type="text"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.name && touched.name ? errors.name : ''}
              hasIcon={false}
            />
            <TextInput
              name="email"
              placeholder="Email"
              type="email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.email && touched.email ? errors.email : ''}
              hasIcon={false}
            />
            <Button
              disabled={invitingVendor}
              loading={invitingVendor}
              type="submit"
              label="Proceed"
              primary
              className="!mt-6 !w-full"
            />
          </Form>
        )}
      </Formik>
    </ModalComponent>
  )
}

export default ActiveVendors
