import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import ProductsTable from './ProductsTable'
import Pagination from '../../components/pagination'
import {
  useGetAllProductsQuery,
  useLazyGetAllProductsQuery,
} from '../../redux-toolkits/products/products.slice'
import Spinner from '../../components/spinner/Spinner'
import { debounce } from 'lodash'
import { PageHeader } from '../../components/typography/PageHeader'
import { TextInput } from '../../components/inputs/textInput'
import { Button } from '../../components/button'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import { useNavigate } from 'react-router-dom'
import { SearchInput } from '../../components/inputs/searchInput'

const handleSearch = (searchFunction: (val: any) => void, query: any) => {
  searchFunction(query)
}

const handleDebouncedSearch = debounce(handleSearch, 800)

const ProductsPage: React.FC = () => {
  const [searchParams, setSearchParams] = useState('')
  const [currentPage, setCurrentPage] = useState<unknown>(1)

  const navigate = useNavigate()

  const [getAllProducts, { data: products, isFetching: loadingProducts }] =
    useLazyGetAllProductsQuery()

  const handlePageChange = (page: number): void => {
    setCurrentPage(page)
  }

  useEffect(() => {
    handleDebouncedSearch(getAllProducts, {
      page: currentPage as string,
      limit: NUMBER_OF_ITEMS_PER_PAGE,
      search: searchParams,
    })
  }, [currentPage])

  return (
    <div>
      <PageHeader title="Products" />

      <div className="flex flex-wrap gap-4 items-center justify-between mb-10">
        <div className="!w-full md:!max-w-[400px]">
          <SearchInput
            searchFieldName="search"
            searchFunction={getAllProducts}
            placeholder="Search products"
            setSearchValue={setSearchParams}
            otherFilterparams={{
              page: '1',
              limit: NUMBER_OF_ITEMS_PER_PAGE,
            }}
          />
        </div>

        <Button
          icon="plus"
          size="medium"
          label="Add new product"
          primary
          onClick={() => {
            navigate('/products/new')
          }}
        />
      </div>

      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total products count"
          count={products?.data.totalItems ?? 0}
        />
      </CountCardContainer>

      <ProductsTable
        productList={products?.data.data || []}
        currentPage={Number(currentPage)}
        totalDataCount={products?.data.totalItems ?? 0}
        pageLimit={NUMBER_OF_ITEMS_PER_PAGE}
        onPageChange={handlePageChange}
        loading={loadingProducts}
        refetch={() => {
          getAllProducts({
            page: currentPage as string,
            limit: String(NUMBER_OF_ITEMS_PER_PAGE),
            search: searchParams,
          })
        }}
      />
    </div>
  )
}

export default ProductsPage
