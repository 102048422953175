import React, { useEffect, useState } from 'react'
import { Section, Sections } from '../../../../types/types'
import ReactHtmlParser from 'react-html-parser'
import { removeElementAtIndex } from '../../../../helpers'
import Icon from '../../../../components/icon'

export const ArticleSectionLiveView = ({
  section,
  setActiveSectionIndex,
  sectionIndex,
  sections,
  setSections,
  isAnyOtherSectionActive,
  disableActions = false,
}: {
  section: Section
  setActiveSectionIndex: (index: number | null) => void
  sectionIndex: number
  sections: Sections
  setSections: (sections: Sections) => void
  isAnyOtherSectionActive: boolean
  disableActions?: boolean
}) => {
  const [allSectionsCopy, setAllSectionsCopy] = useState<Sections>(
    JSON.parse(JSON.stringify(sections)),
  )

  const hasImage = section.content.some((item) => item.type === 'image')

  useEffect(() => {
    setAllSectionsCopy(JSON.parse(JSON.stringify(sections)))
  }, [sections])

  return (
    <div className="min-h-[100px] flex flex-col flex-wrap md:flex-nowrap gap-4 relative">
      {/* Edit button  */}
      {!isAnyOtherSectionActive && !disableActions && (
        <div
          className="
          absolute
          top-0 right-0
          z-10
          flex items-center gap-2
          "
        >
          <button
            type="button"
            className="
            bg-sec-black text-white
            p-2 rounded-full
            "
            onClick={() => {
              setActiveSectionIndex(sectionIndex)
            }}
          >
            <Icon id="edit-icon" width={16} height={16} />
          </button>

          <button
            type="button"
            className="
            bg-danger text-white
            p-2 rounded-full
        "
            onClick={() => {
              const temp: Sections = removeElementAtIndex(
                allSectionsCopy,
                sectionIndex,
              )
              setSections(temp)
              setActiveSectionIndex(null)
            }}
          >
            <Icon id="bin" width={16} height={16} />
          </button>
        </div>
      )}

      {section.content.map((subSection, idx) => {
        const key = `subSection-${idx}`

        let productPlaceholders: string[] = []
        let productPlaceholdersArray = []
        let productPlaceholdersObject: {
          [keyField: string]: string
        } = {}

        if (subSection.type == 'text') {
          productPlaceholders =
            subSection.value.match(/__product_[a-f0-9]+__/g) || []

          productPlaceholdersArray =
            productPlaceholders?.length > 0
              ? productPlaceholders?.map((placeholder, idx) => {
                  const placeholderSplit = placeholder.split('_')
                  return {
                    [placeholder]: `
                        <a
                        href="/product?id=${subSection?.products.filter(
                          (el) => el._id === placeholderSplit[3],
                        )[0]?._id}"
                        class="
                        font-black underline underline-offset-[5px] 
                        recoleta
                        cursor-pointer
                        "
                        >
                        ${subSection?.products.filter(
                          (el) => el._id === placeholderSplit[3],
                        )[0]?.name}
                        </a>
                        `,
                  }
                })
              : []

          productPlaceholdersObject = Object.assign(
            {},
            ...productPlaceholdersArray,
          )
        }

        const displayText =
          subSection.type === 'text' && productPlaceholdersArray?.length > 0
            ? subSection.value.replace(
                new RegExp(productPlaceholders?.join('|'), 'gi'),
                function (matched) {
                  return (
                    productPlaceholdersObject &&
                    productPlaceholdersObject[matched]
                  )
                },
              )
            : subSection.value

        const characterCount = subSection.value?.trim()?.split(/\s+/)?.length
        const maxWidth = `${Math.floor(
          ((characterCount * 37) / (111 * 100)) * 1272 + 20,
        )}px`

        return subSection.type === 'text' ? (
          <div
            key={key}
            className={`
              !min-w-[200px] !w-full 
              [@media(max-width:767px)]:!max-w-full  
              only:!max-w-full break-words hyphens-auto
              `}
            style={{ maxWidth: hasImage ? '100%' : '100%' }}
          >
            {ReactHtmlParser(displayText)}
          </div>
        ) : (
          <div className="" key={key}>
            <img
              src={subSection.value}
              width={0}
              height={0}
              alt="article asset"
              className="min-w-[100px] w-auto h-full object-contain !relative"
            />
          </div>
        )
      })}
    </div>
  )
}
