import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import {
  CURRENT_PAGE_NUMBER,
  GOOD_ONLINE_BACKEND_BASE_URL,
  NUMBER_OF_ITEMS_PER_PAGE,
} from '../../constants'
import { apiSlice, goodOnlineApiSlice } from '../api/apiSlice'
import { WaitlistSubscriptionResponseData } from './waitlist.type'

interface QueryParams {
  skip?: number
  limit?: number
}

export const waitlistApiSlice = goodOnlineApiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    fetchWaitlistSubscriptions: builder.query<
      WaitlistSubscriptionResponseData,
      QueryParams
    >({
      query: ({ limit = NUMBER_OF_ITEMS_PER_PAGE, skip }) => ({
        url: `contact-us/emails?skip=${
          skip ? (skip - 1) * limit : CURRENT_PAGE_NUMBER
        }&limit=${limit ?? NUMBER_OF_ITEMS_PER_PAGE}
      `,
        method: 'GET',
      }),
      transformResponse: (data: WaitlistSubscriptionResponseData) => {
        return data
      },
    }),
  }),
})

export const { useFetchWaitlistSubscriptionsQuery } = waitlistApiSlice
