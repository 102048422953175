import { type PayloadAction, createSlice, type Slice } from '@reduxjs/toolkit'
import {
  type TeamMember,
  type CurrentUserResponse,
  type Role,
  type Permission,
  type UpdatedTeamMember,
} from './user.type'

export interface UserReducer {
  roles: Role[]
  teamMembers: TeamMember[]
  permissions: Permission[]
  users: CurrentUserResponse[] | []
  user: CurrentUserResponse | null
  isFetchingUsers: boolean
  paginationInfo: {
    currentPage: number
    hasNext: boolean
    hasPrevious: boolean
    pageSize: number
    pages: number
    totalItems: number
  } | null
}

const initialState: UserReducer = {
  roles: [],
  teamMembers: [],
  permissions: [],
  users: [],
  user: null,
  isFetchingUsers: false,
  paginationInfo: null,
}

const userReducer: Slice<UserReducer> = createSlice({
  name: 'users',
  initialState,
  reducers: {
    modifyTeamMember: (
      state: UserReducer,
      action: PayloadAction<UpdatedTeamMember>,
    ) => {
      const index = state.teamMembers.findIndex(
        (teamMember) => teamMember._id === action.payload._id,
      )
      const currentTeamMember = state.teamMembers[index]
      const currentRole = state.roles.find(
        (role) => role._id === action.payload.role,
      )
      currentTeamMember.role = currentRole ?? currentTeamMember.role
      currentTeamMember.firstname = action.payload.firstname
      currentTeamMember.lastname = action.payload.lastname

      state.teamMembers[index] = currentTeamMember
    },
    createTeamMember: (
      state: UserReducer,
      action: PayloadAction<TeamMember>,
    ) => {
      state.teamMembers.push(action.payload)
    },
    deactivateActivateMember: (
      state: UserReducer,
      action: PayloadAction<{ id: string; deleted: boolean }>,
    ) => {
      const index = state.teamMembers.findIndex(
        (member) => member._id === action.payload.id,
      )
      state.teamMembers[index].deleted = action.payload.deleted
    },
    setTeamMembers: (state: UserReducer, action) => {
      state.teamMembers = action.payload
    },
    setPermissions: (state: UserReducer, action) => {
      state.permissions = action.payload
    },
    addPermission: (state: UserReducer, action: PayloadAction<Permission>) => {
      state.permissions.push(action.payload)
    },
    modifyPermission: (
      state: UserReducer,
      action: PayloadAction<Permission>,
    ) => {
      const index = state.permissions.findIndex(
        (permission) => permission._id === action.payload._id,
      )
      state.permissions[index] = action.payload
    },
    setRoles: (state: UserReducer, action) => {
      state.roles = action.payload
    },
    addRole: (state: UserReducer, action: PayloadAction<Role>) => {
      state.roles.push(action.payload)
    },
    modifyRole: (state: UserReducer, action: PayloadAction<Role>) => {
      const index = state.roles.findIndex(
        (role) => role._id === action.payload._id,
      )
      state.roles[index] = action.payload
    },
    setUsers: (state: UserReducer, action) => {
      state.users = action.payload
      state.paginationInfo = action.payload.paginationInfo
    },
    setUser: (
      state: UserReducer,
      action: PayloadAction<CurrentUserResponse>,
    ) => {
      state.user = action.payload
    },
    setFetchingUser: (state: UserReducer, action) => {
      state.isFetchingUsers = action.payload
    },
  },
})

export const {
  setUser,
  setUsers,
  setFetchingUser,
  setTeamMembers,
  setRoles,
  setPermissions,
  createTeamMember,
  modifyTeamMember,
  addPermission,
  modifyPermission,
  addRole,
  modifyRole,
  deactivateActivateMember,
} = userReducer.actions
export default userReducer.reducer
