import React from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import { CreateCategoryPayload } from '../../../redux-toolkits/products/products.type'
import { CreateCategorySchema } from '../../../features/users/validate'
import {
  useCreateProductCategoryMutation,
  useEditProductCategoryMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'

export const AddCategoryModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  mode: 'edit' | 'create'
  category?: ListCategoryDoc
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  mode,
  category,
}) => {
  const [createCategory, { isLoading: creating }] =
    useCreateProductCategoryMutation()
  const [editCategory, { isLoading: editing }] =
    useEditProductCategoryMutation()

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      name: category?.name ?? '',
      description: category?.description ?? '',
    },
    enableReinitialize: true,
    validationSchema: CreateCategorySchema,
    onSubmit: (values) => {
      if (mode === 'create') {
        createCategory(values).then((resp) => {
          if (resp.data?.success) {
            resetForm()
            setOpen(false)
            onSuccessCallback()
          }
        })
      } else if (mode === 'edit' && category) {
        editCategory({ id: category?._id, payload: values }).then((resp) => {
          if (resp.data?.success) {
            resetForm()
            setOpen(false)
            onSuccessCallback()
          }
        })
      }
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={mode === 'create' ? 'Add new category' : 'Edit category'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name="name"
          placeholder="Category name"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.name && touched.name ? errors.name : ''}
          hasIcon={false}
          className="!border-divider-gray"
        />

        <TextInput
          type="textarea"
          name="description"
          placeholder="Category description"
          value={values.description}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.description && touched.description ? errors.description : ''
          }
          hasIcon={false}
          className="!border-divider-gray"
        />

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid || creating || editing}
          loading={creating || editing}
          primary
        />
      </form>
    </ModalComponent>
  )
}
