import React, { ReactNode } from 'react'
import Icon from '../icon'

export const AddNewCard: React.FC<{
  className?: string
  onClick: () => void
  customChildren?: ReactNode
}> = ({ className, onClick, customChildren }) => {
  return (
    <button
      type="button"
      className={`
              bg-primary/5
              border border-primary
              text-primary 
              border-dashed
              flex items-center justify-center
              rounded-lg
              hover:scale-[0.98]
              transition-all duration-300
              cursor-pointer
              min-h-[200px]
              ${className}
              `}
      onClick={() => {
        onClick()
      }}
    >
      {customChildren ? customChildren : <Icon id="add-icon-2" />}
    </button>
  )
}
