import React from 'react'
import { type ReactNode } from 'react'
import { Provider } from 'react-redux'
import { store } from '../store'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

interface Props {
  children: ReactNode
}
const persistor = persistStore(store)

export const Providers: React.FC<Props> = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  )
}
