import React from 'react'

interface Props {
  id: string
  height?: number
  width?: number
  className?: string
}

export default function Icon({
  id,
  height = 40,
  width = 40,
  className = '',
  ...props
}: Props): JSX.Element {
  return (
    <svg width={width} height={height} className={className} {...props}>
      <use
        xlinkHref={`${process.env.PUBLIC_URL}/assets/icons/icon-sprite.svg#${id}`}
      />
    </svg>
  )
}
