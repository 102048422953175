import React from 'react'
import Layout from '../../layout'
import UserForm from './UserForm'

export const CreateUserPage: React.FC = () => {
  return (
    <Layout>
      <div className="flex justify-between space-x-6">
        <UserForm />
      </div>
    </Layout>
  )
}
