import React from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  Tag,
} from '../../../redux-toolkits/products/products.type'
import {
  AddWalletAmountSchema,
  CreateCategorySchema,
  CreateTagSchema,
} from '../../../features/users/validate'
import {
  useCreateProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductCategoryMutation,
  useEditProductTagMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import { useAddWalletAmountMutation } from '../../../redux-toolkits/user/user.slice'

export const AddWalletAmountModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
}) => {
  const [addWalletAmount, { isLoading: creating }] =
    useAddWalletAmountMutation()

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      amount: '',
    },
    enableReinitialize: true,
    validationSchema: AddWalletAmountSchema,
    onSubmit: (values) => {
      addWalletAmount(values).then((resp) => {
        if (resp.data?.success) {
          resetForm()
          setOpen(false)
          onSuccessCallback()
        }
      })
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={'Add quick fund amount'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name="amount"
          placeholder="Amount"
          value={values.amount}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.amount && touched.amount ? errors.amount : ''}
          hasIcon={false}
        />

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid || creating}
          loading={creating}
          primary
        />
      </form>
    </ModalComponent>
  )
}
