import { apiSlice } from '../api/apiSlice'
import type {
  AnalyticsDuration,
  OrdersAnalyticsResponse,
} from '../../redux-toolkits/orders/orders.type'

export const orderAnalyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderAnalytics: builder.query<
      OrdersAnalyticsResponse,
      { duration?: AnalyticsDuration }
    >({
      query: (payload) => ({
        url: `/order/analytics`,
        method: 'GET',
        params: {
          duration: payload.duration ?? 'year',
        },
      }),
    }),
  }),
})

export const { useGetOrderAnalyticsQuery } = orderAnalyticsApiSlice
