import React, { useState } from 'react'
import * as Yup from 'yup'
import {
  useCreateRoleMutation,
  useEditRoleMutation,
  useGetPermissionsQuery,
  useGetRolesQuery,
} from '../../redux-toolkits/user/user.slice'
import { useAppSelector } from '../../hooks'
import { userSelector } from '../../redux-toolkits/user/user.selector'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { type Permission, type Role } from '../../redux-toolkits/user/user.type'
import Spinner, { LoadingOval } from '../../components/spinner/Spinner'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import { Button } from '../../components/button'
import { TableComponent } from '../../components/table'
import Icon from '../../components/icon'
import { Status } from '../../components/cards/statusTag'
import Dropdown from '../../components/inputs/dropdown'
import { PRIMARY_COLOR } from '../../constants'
import { TextInput } from '../../components/inputs/textInput'
import ModalComponent from '../../components/modal'

const RolesSection: React.FC = () => {
  const [editing, setEditing] = useState<Role | undefined>(undefined)
  const [openModal, setOpenModal] = useState(false)
  const { isLoading } = useGetRolesQuery({
    skip: 0,
    limit: 100,
  })
  const [editRole, { isLoading: editingRole }] = useEditRoleMutation()

  useGetPermissionsQuery({
    skip: 0,
    limit: 100,
  })

  const { roles, permissions } = useAppSelector(userSelector)

  const closeModal = (): void => {
    setOpenModal(false)
    setEditing(undefined)
  }

  return (
    <div>
      <div className="flex flex-col gap-10">
        <div className="flex items-center">
          <HeaderSubheader
            title="Roles"
            text="Manage the roles and permissions of your team members."
          />

          <Button
            type="button"
            label="Create new role"
            primary
            size="medium"
            onClick={() => {
              setOpenModal(true)
            }}
            icon="plus"
          />
        </div>

        <TableComponent
          loading={isLoading}
          headers={['Name', 'Description', 'Permission count', 'Status', ' ']}
          rows={roles.map((role) => {
            return {
              id: role._id,
              content: [
                role.name,
                role.description,
                role.permissions.length.toLocaleString(),
                <Status
                  key={`${role._id}-status`}
                  text={!role.active ? 'Inactive' : 'Active'}
                  type={!role.active ? 'fail' : 'success'}
                />,
                <Dropdown
                  className="text-left"
                  key={`${role._id}-controls`}
                  menuButton={
                    <Icon id="ellipses" height={18} width={18} className="" />
                  }
                  closeOnClick
                  onClickMenuItem={() => {}}
                  menuItems={[
                    {
                      name: (
                        <button
                          onClick={() => {
                            setEditing(role)
                            setOpenModal(true)
                          }}
                          className="w-full text-left"
                        >
                          Edit
                        </button>
                      ),
                      value: '',
                    },
                    {
                      name: (
                        <button
                          onClick={async (e) => {
                            e.stopPropagation()
                            await editRole({
                              id: role._id,
                              payload: { active: !role.active },
                            })
                            closeModal()
                          }}
                          className="w-full text-left flex items-center gap-2 justify-between"
                        >
                          {!role.active ? 'Activate' : 'Deactivate'}

                          {editingRole && (
                            <LoadingOval
                              color={PRIMARY_COLOR}
                              loaderWidth="16"
                              loaderHeight="16"
                            />
                          )}
                        </button>
                      ),
                      value: '',
                    },
                  ]}
                />,
              ],
            }
          })}
        />
      </div>
      <CreateRoleModal
        role={editing}
        closeModal={closeModal}
        permissions={permissions}
        open={openModal}
      />
    </div>
  )
}

const CreateRoleModal: React.FC<{
  open: boolean
  closeModal: () => void
  permissions: Permission[]
  role?: Role
}> = ({ open, closeModal, permissions, role }) => {
  const [createRole, { isLoading: creatingRole }] = useCreateRoleMutation()
  const [editRole, { isLoading: updatingRole }] = useEditRoleMutation()

  return (
    <>
      <ModalComponent
        title={`${role !== undefined ? 'Edit' : 'Create new'} role`}
        open={open}
        setOpen={() => {}}
        onCloseCallback={() => {
          closeModal()
        }}
      >
        <Formik
          initialValues={{
            name: role?.name ?? '',
            description: role?.description ?? '',
            permissions:
              role?.permissions.map((permission) => permission._id) ?? [],
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required'),
            description: Yup.string().required('Description is required'),
          })}
          onSubmit={async (values) => {
            if (role !== null && role !== undefined) {
              await editRole({ payload: values, id: role._id })
            } else {
              await createRole(values)
            }
            closeModal()
          }}
        >
          {({ values, handleBlur, handleChange, errors, touched }) => (
            <Form className="flex flex-col gap-4">
              <TextInput
                type="text"
                name="name"
                placeholder="Role name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.name && touched.name ? errors.name : ''}
                hasIcon={false}
              />

              <TextInput
                type="textarea"
                name="description"
                placeholder="Role description"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.description && touched.description
                    ? errors.description
                    : ''
                }
                hasIcon={false}
              />

              <div>
                <HeaderSubheader
                  title="Permissions"
                  text="Select Permissions for this role"
                />
                <div className="mt-2 h-60 overflow-scroll flex flex-col">
                  {permissions?.map((permission) => (
                    <div key={permission._id}>
                      <label
                        className="
                        flex gap-2
                        items-center justify-between
                        cursor-pointer
                        py-2 border-b border-divider-gray
                        "
                      >
                        <div>
                          <p className="text-sm font-medium">
                            {permission.name}
                          </p>
                          <p className="text-xs text-sec-black">
                            {permission.description}
                          </p>
                        </div>

                        <Field
                          type="checkbox"
                          name="permissions"
                          value={permission._id}
                          className="
                          h-4 border-2
                          px-2 outline-none
                          text-primary bg-primary
                          "
                        />
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <Button
                disabled={creatingRole || updatingRole}
                loading={creatingRole || updatingRole}
                type="submit"
                label="Proceed"
                primary
              />
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  )
}

export default RolesSection
