import React, { useEffect, useState } from 'react'
import Layout from '../../../layout'
import {
  useDeleteListMutation,
  useFetchListCommentsQuery,
  useFetchListMutation,
  useGetListProductsQuery,
} from '../../../redux-toolkits/lists/list.slice'
import { useParams } from 'react-router'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../../constants'
import Pagination from '../../../components/pagination'
import CountCard from '../../orders/CountCard'
import { CircleSlash } from 'lucide-react'
import Spinner from '../../../components/spinner/Spinner'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from '../../../components/typography/PageHeader'
import { HeaderSubheader } from '../../../components/typography/HeaderSubheader'
import { Button } from '../../../components/button'
import { CountCardContainer } from '../../../components/wrapper/CountCardsContainer'
import { ImageBanner } from '../../../components/images/imageBanner'
import { EmptyState } from '../../../components/emptyState/emptyState'
import { ConfirmDeleteModal } from '../../../components/modal/app_modals/confirmDeleteModal'

export const SingleCuratedListPage: React.FC = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { id } = useParams<{ id: string }>()

  const [query, setQuery] = useState({
    skip: 1,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
  })

  const [fetchList, { data: listInfo }] = useFetchListMutation()

  const { data, isFetching } = useFetchListCommentsQuery({
    id: id ?? '',
    query,
  })

  const { data: listProducts, isFetching: fetchingList } =
    useGetListProductsQuery(id || '')

  const [deleteList, { isLoading: deleting }] = useDeleteListMutation()

  useEffect(() => {
    if (!id) return
    fetchList(id)
  }, [])

  return (
    <Layout>
      {!isFetching ? (
        <div>
          <PageHeader title="List Details and Metrics" />

          <div className="flex justify-end items-center gap-4">
            <Button
              label="Edit list"
              primary={false}
              size="medium"
              className="!px-10"
              onClick={() => {
                navigate(`/lists/curated/edit/${id}`)
              }}
            />

            <Button
              label="Delete list"
              primary
              size="medium"
              className="!px-10 !bg-danger !border-transparent"
              onClick={() => {
                setOpen(true)
              }}
            />
          </div>

          <div className="pb-10 mt-10 border-b border-divider-gray">
            <HeaderSubheader
              title={listInfo?.data.title ?? ''}
              text={listInfo?.data.summary ?? ''}
              className=""
            />

            <ImageBanner
              imageUrl={listInfo?.data.banner ?? ''}
              loading={false}
              className="mt-6"
            />
          </div>

          <div className="pb-10 mt-10 border-b border-divider-gray">
            <HeaderSubheader
              title="Products"
              text="Collection of products in this list"
              className="mb-6"
            />
            {listProducts?.data?.products && listProducts?.data?.products[0] ? (
              <div
                className="
                  grid grid-flow-col !auto-cols-[minmax(200px,200px)]
                  gap-4 
                  "
              >
                {listProducts?.data?.products.map((product) => (
                  <div
                    className="
                        flex flex-col gap-2 font-normal
                        "
                    key={product._id}
                  >
                    <div className="border flex-1 rounded-lg">
                      <img
                        src={product.images[0]}
                        alt={product.name}
                        className="
                        object-cover rounded-lg 
                        overflow-hidden
                        h-full w-full
                        "
                      />
                    </div>
                    <span className="black text-sm">{product.name}</span>
                  </div>
                ))}
              </div>
            ) : (
              <EmptyState text="No products have been added to this list" />
            )}
          </div>

          <div className="pb-10 mt-10 flex flex-col gap-6 border-b border-divider-gray">
            <HeaderSubheader title="List Impressions" />

            <CountCardContainer className="!my-0">
              <CountCard
                count={0}
                text="Total impressions"
                icon=""
                isCurrency={false}
              />
            </CountCardContainer>
          </div>

          <div className="mt-10 flex flex-col gap-6">
            <HeaderSubheader title="Comments" />

            {!isFetching && data?.data?.docs?.length ? (
              <div className="py-4">
                {data?.data?.docs?.map((doc, idx) => {
                  const key = `list-comment-${idx}`
                  return (
                    <div key={key} className="flex flex-col gap-3">
                      <div className="flex flex-row gap-2 items-center">
                        <div
                          className="
                        bg-black
                        text-white
                        flex items-center justify-center
                        w-[48px] h-[48px]
                        rounded-full
                        font-bold
                      "
                        >{`${doc.name.split(' ')[0][0]} ${
                          doc.name.split(' ')[1][0]
                        }`}</div>
                        <div className="text-sm">{doc.name}</div>
                      </div>
                      <div>{doc.message}</div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <EmptyState text="No comments" />
            )}

            <Pagination
              currentPage={query?.skip ?? 0}
              totalCount={data?.data?.total ?? 0}
              itemsPerPage={query.limit}
              pageRangeDisplayed={3}
              onPageChange={(pageNo) => {
                setQuery({ ...query, skip: pageNo })
              }}
            />
          </div>
        </div>
      ) : (
        <Spinner fullScreen={false} />
      )}

      <ConfirmDeleteModal
        open={open}
        setOpen={setOpen}
        message={`Are you sure you want to delete '${
          listInfo?.data.title ?? ''
        }'? This action cannot be reversed.`}
        onCloseCallback={() => {}}
        onConfirm={() => {
          deleteList(listInfo?.data._id ?? '')?.then((resp) => {
            if (resp.data?.success) {
              navigate('/lists/curated')
            }
          })
        }}
        loading={deleting}
      />
    </Layout>
  )
}
