import React from 'react'
import ModalComponent from '..'
import { Button } from '../../button'

export const ConfirmDeleteModal = ({
  open,
  setOpen,
  message,
  title,
  onCloseCallback,
  onConfirm,
  loading,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  message: React.ReactNode
  title?: string
  onCloseCallback?: () => void
  onConfirm: () => void
  loading?: boolean
}) => {
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={onCloseCallback}
      title={title ?? 'Confirm Deletion'}
    >
      <div>
        <span className="text-lg">{message}</span>

        <div className="grid grid-cols-2 mt-10 gap-4">
          <Button
            label="Cancel"
            onClick={() => {
              onCloseCallback && onCloseCallback()
              setOpen(false)
            }}
            primary
          />

          <Button
            label="Confirm"
            loading={loading}
            disabled={loading}
            onClick={() => {
              onConfirm()
            }}
            className="!bg-danger !border-transparent !text-white"
          />
        </div>
      </div>
    </ModalComponent>
  )
}
