import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Providers } from './redux-toolkits/providers'
import { ToastContainer } from 'react-toastify'
import '@fontsource/geist-sans/100.css'
import '@fontsource/geist-sans/200.css'
import '@fontsource/geist-sans/300.css'
import '@fontsource/geist-sans/400.css'
import '@fontsource/geist-sans/500.css'
import '@fontsource/geist-sans/600.css'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <Providers>
    <React.StrictMode>
      <ToastContainer autoClose={2000} position="top-center" />
      <App />
    </React.StrictMode>
  </Providers>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
