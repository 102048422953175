import React from 'react'

export const ErrorText = ({ text }: { text: string }) => {
  return (
    <span
      className="
          text-sm text-danger
          block p-2 font-normal
          "
    >
      {text}
    </span>
  )
}
