import React from 'react'
import Layout from '../../layout'
import { useGetAllUsersQuery } from '../../redux-toolkits/user/user.slice'
import Icon from '../../components/icon'
import { useNavigate } from 'react-router'
import Spinner from '../../components/spinner/Spinner'

export const UsersPage: React.FC = () => {
  const navigate = useNavigate()
  // const [userData, setUserData] = useState<CurrentUserResponse>({
  //   _id: 0,
  //   email: '',
  //   firstname: '',
  //   lastname: '',
  //   firstLogin: false,
  //   createdAt: '',
  //   updatedAt: '',
  // })
  const {
    data: users,
    isLoading,
    isError,
  } = useGetAllUsersQuery({
    skip: 0,
    limit: 20,
  })

  // const [activateUser] = useActivateUserMutation()

  if (isLoading) {
    return <Spinner />
  } else if (isError) {
    return <p>Oops! something went wrong.</p>
  }

  // const clearUserData = (): void => {
  //   setUserData({
  //     _id: 0,
  //     email: '',
  //     firstname: '',
  //     lastname: '',
  //     firstLogin: false,
  //     createdAt: '',
  //     updatedAt: '',
  //   })
  // }

  // const showTooltip = (active: string): string => {
  //   if (active === 'true') {
  //     return 'Deactivate user'
  //   } else {
  //     return 'Activate user'
  //   }
  // }

  // const handleActivateUser = async ({
  //   id,
  //   active,
  // }: {
  //   id: number
  //   active: boolean
  // }): Promise<void> => {
  //   try {
  //     await activateUser({ id, active })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  return (
    <Layout>
      <div className="flex justify-between space-x-6">
        <div className="flex-1">
          <table className="table-fixed w-full bg-white">
            <thead>
              <tr>
                <th className="border border-slate-200 p-4 w-20">SN</th>
                <th className="border border-slate-200 p-4">Name</th>
                <th className="border border-slate-200 p-4">Email</th>
                <th className="border border-slate-200 p-4">Phone</th>
                <th className="border border-slate-200 p-4 w-72">Action</th>
              </tr>
            </thead>
            <tbody>
              {users?.data.map((user, i) => (
                <tr key={user._id}>
                  <td className="border border-slate-200 p-4 w-20">{i + 1}</td>
                  <td className="border border-slate-200 p-4">
                    {user.firstname} {user.lastname}
                  </td>
                  <td className="border border-slate-200 p-4">{user.email}</td>
                  <td className="border border-slate-200 p-4 space-x-3 w-72">
                    <button
                      onClick={() => {
                        navigate(`/users/${user._id}/details`)
                      }}
                      className="bg-orange-600 p-2 rounded-lg text-white"
                    >
                      <Icon id="view-icon" width={20} height={20} />
                    </button>
                    <button
                      onClick={() => {
                        // setUserData({ ...user })
                      }}
                      className="bg-green-600 p-2 rounded-lg text-white"
                    >
                      <Icon id="edit-icon" width={20} height={20} />
                    </button>
                    {/* <button
                      onClick={() => {
                        handleActivateUser({
                          id: user._id,
                          active: user.active,
                        })
                          .then((res) => {})
                          .catch((err): any => {
                            console.log(err)
                          })
                      }}
                      title={showTooltip(String(user.active))}
                    >
                      <Switch checked={user.active} />
                      <label className="text-xs">
                        {showTooltip(String(user.active))}
                      </label>
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}
