import React from 'react'
import Layout from '../../layout'
import { Discounts } from './discounts'

export const DiscountPage = () => {
  return (
    <Layout>
      <Discounts />
    </Layout>
  )
}
