import React, { ReactNode } from 'react'
import TopNavigation from '../top-nav/TopNavigation'

export const AuthWrapper: React.FC<{
  header: string
  className?: string
  children: ReactNode
}> = ({ header, className, children }) => {
  return (
    <div
      className="
      h-screen w-screen 
      flex flex-col
      "
    >
      <TopNavigation />

      <div
        className="
        flex-1 w-full
        flex items-center justify-center
        "
      >
        <div
          className={`
            
        ${className}
        `}
        >
          <span
            className="
        block mb-10
        font-recoleta-bold
        text-4xl
        w-full text-center
        "
          >
            {header}
          </span>

          {children}
        </div>
      </div>
    </div>
  )
}
