import React, { useState } from 'react'
import { Button } from '../../components/button'
import { PageHeader } from '../../components/typography/PageHeader'
import { AddDiscountModal } from '../../components/modal/app_modals/addDiscountModal'
import { useModalControl } from '../../hooks/useModalControl'
import { useGetDiscountsQuery } from '../../redux-toolkits/products/products.slice'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { TableComponent } from '../../components/table'
import { formatCurrency } from '../../helpers'
import { format } from 'date-fns'
import { Status } from '../../components/cards/statusTag'
import { SingleDiscount } from '../../redux-toolkits/products/products.type'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'

export const Discounts = () => {
  const [query, setQuery] = useState({
    page: 1,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
  })
  const [selected, setSelected] = useState<SingleDiscount>()

  const { modalOpen, modal, handleModalClose, handleModalOpen, setModalOpen } =
    useModalControl()

  const { data, isFetching, refetch } = useGetDiscountsQuery(query)

  return (
    <div>
      <PageHeader title="Discounts" />

      <div className="flex flex-wrap gap-4 items-center justify-end mb-10">
        <Button
          icon="plus"
          size="medium"
          label="Add new discount"
          primary
          onClick={() => {
            handleModalOpen('addDiscountModal')
          }}
        />
      </div>

      <TableComponent
        headers={[
          'Id',
          'Minumum amount',
          'Maximum amount',
          'Status',
          'creation date',
          '',
        ]}
        rows={
          data?.data.discounts.map((discount) => {
            return {
              id: discount._id,
              content: [
                <button
                  key={`${discount._id}-button`}
                  className="
                "
                  type="button"
                  onClick={() => {
                    setSelected(discount)
                    handleModalOpen('addDiscountModal')
                  }}
                >
                  {discount._id}
                </button>,
                formatCurrency(discount.minimumAmount),
                formatCurrency(discount.maximumAmount),
                <Status
                  key={`${discount._id}-status`}
                  text={discount.status}
                  type={discount.status === 'active' ? 'success' : 'fail'}
                />,
                format(new Date(discount.createdAt), 'yyyy-MM-dd'),
                <Dropdown
                  key={`${discount._id}-controls`}
                  menuButton={
                    <Icon id="ellipses" height={18} width={18} className="" />
                  }
                  onClickMenuItem={() => {}}
                  menuItems={[
                    {
                      name: (
                        <button
                          className="disabled:opacity-30 w-full text-left"
                          onClick={(): any => {
                            setSelected(discount)
                            handleModalOpen('addDiscountModal')
                          }}
                        >
                          Edit
                        </button>
                      ),
                      value: '',
                    },
                  ]}
                />,
              ],
            }
          }) ?? []
        }
        loading={isFetching}
        currentPage={query.page}
        totalDataCount={data?.data.count}
        pageLimit={query.limit}
        onPageChange={(page) => {
          setQuery({ ...query, page: page })
        }}
      />

      <AddDiscountModal
        open={modalOpen && modal === 'addDiscountModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelected(undefined)
        }}
        onSuccessCallback={() => {
          handleModalClose()
          refetch()
        }}
        discount={selected}
      />
    </div>
  )
}
