import React from 'react'
import Layout from '../../../layout'
import ListArticlesTable from './components/listArticlesTable'
import { Button } from '../../../components/button'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from '../../../components/typography/PageHeader'

export const ListArticles = () => {
  const navigate = useNavigate()

  return (
    <Layout>
      <PageHeader title="Articles Lists" />

      <div className="w-full flex justify-end">
        <Button
          label="Create article"
          onClick={() => {
            navigate(`/lists/articles/create`)
          }}
          primary
          size="medium"
          icon="plus"
        />
      </div>

      <ListArticlesTable className="mt-10" />
    </Layout>
  )
}
