import React, { useEffect, useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  CreateDiscount,
  SingleDiscount,
} from '../../../redux-toolkits/products/products.type'
import {
  AddDiscountSchema,
  addLocationManualSchema,
  CreateCategorySchema,
} from '../../../features/users/validate'
import {
  useCreateDiscountMutation,
  useCreateProductCategoryMutation,
  useEditDiscountMutation,
  useEditProductCategoryMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import AddressInput from '../../inputs/addressInput'
import { useAddAvailableLocationsMutation } from '../../../redux-toolkits/user/user.slice'
import { AddAvailableLocationsPayload } from '../../../redux-toolkits/user/user.type'
import { TabNav } from '../../nav/tabNav'

export const AddDiscountModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  discount?: SingleDiscount
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  discount,
}) => {
  const [selectedDiscount, setSelectedDiscount] = useState<
    SingleDiscount | undefined
  >(discount)

  const [createDiscount, { isLoading: creating }] = useCreateDiscountMutation()
  const [editDiscount, { isLoading: editing }] = useEditDiscountMutation()

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      minimumAmount: selectedDiscount
        ? String(selectedDiscount.minimumAmount)
        : '',
      maximumAmount: selectedDiscount
        ? String(selectedDiscount.maximumAmount)
        : '',
      walletDiscountAmount: selectedDiscount
        ? String(selectedDiscount.discountAmount.walletDiscountAmount)
        : '',
      bankTransferDiscountAmount: selectedDiscount
        ? String(selectedDiscount.discountAmount.bankTransferDiscountAmount)
        : '',
      cardDiscountAmount: selectedDiscount
        ? String(selectedDiscount.discountAmount.cardDiscountAmount)
        : '',
    },
    enableReinitialize: true,
    validationSchema: AddDiscountSchema,
    onSubmit: (vals) => {
      const payload: CreateDiscount = {
        minimumAmount: Number(vals.minimumAmount),
        maximumAmount: Number(vals.maximumAmount),
        discountAmount: {
          walletDiscountAmount: Number(vals.walletDiscountAmount),
          bankTransferDiscountAmount: Number(vals.bankTransferDiscountAmount),
          cardDiscountAmount: Number(vals.cardDiscountAmount),
        },
      }

      selectedDiscount?._id
        ? editDiscount({
            id: selectedDiscount._id,
            data: payload,
          })?.then((resp) => {
            if (resp.data?.success) {
              resetForm()
              onSuccessCallback()
            }
          })
        : createDiscount(payload)?.then((resp) => {
            if (resp.data?.success) {
              resetForm()
              onSuccessCallback()
            }
          })
    },
  })

  useEffect(() => {
    setSelectedDiscount(discount)
  }, [discount])

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={`Add new discount`}
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4">
          <TextInput
            title="Minimum amount"
            type="text"
            name="minimumAmount"
            placeholder="Minimum amount"
            value={values.minimumAmount}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={
              errors.minimumAmount && touched.minimumAmount
                ? errors.minimumAmount
                : ''
            }
            hasIcon={false}
            className="!border-divider-gray"
          />

          <TextInput
            title="Maximum amount"
            type="text"
            name="maximumAmount"
            placeholder="Maximum amount"
            value={values.maximumAmount}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={
              errors.maximumAmount && touched.maximumAmount
                ? errors.maximumAmount
                : ''
            }
            hasIcon={false}
            className="!border-divider-gray"
          />
        </div>

        <TextInput
          title="Wallet discount amount"
          type="text"
          name="walletDiscountAmount"
          placeholder="Wallet discount amount"
          value={values.walletDiscountAmount}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.walletDiscountAmount && touched.walletDiscountAmount
              ? errors.walletDiscountAmount
              : ''
          }
          hasIcon={false}
          className="!border-divider-gray"
        />

        <TextInput
          title="Bank transfer discount amount"
          type="text"
          name="bankTransferDiscountAmount"
          placeholder="Bank transfer discount amount"
          value={values.bankTransferDiscountAmount}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.bankTransferDiscountAmount &&
            touched.bankTransferDiscountAmount
              ? errors.bankTransferDiscountAmount
              : ''
          }
          hasIcon={false}
          className="!border-divider-gray"
        />

        <TextInput
          title="Card discount amount"
          type="text"
          name="cardDiscountAmount"
          placeholder="Card discount amount"
          value={values.cardDiscountAmount}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.cardDiscountAmount && touched.cardDiscountAmount
              ? errors.cardDiscountAmount
              : ''
          }
          hasIcon={false}
          className="!border-divider-gray"
        />

        <Button
          label="Proceed"
          type="submit"
          className="mt-10 w-full"
          disabled={creating || editing || !isValid}
          loading={creating || editing}
          primary
        />
      </form>
    </ModalComponent>
  )
}
