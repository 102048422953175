import { useState } from 'react'

export const useModalControl = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modal, setModal] = useState<string>('')

  const handleModalOpen = (name: string) => {
    setModal(name)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModal('')
    setModalOpen(false)
  }

  return { modal, modalOpen, setModalOpen, handleModalOpen, handleModalClose }
}
