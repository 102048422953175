import { toast } from 'react-toastify'
import type {
  ImageUploadBody,
  ImageUploadResponse,
  QueryProps,
  TransactionsResponse,
} from '../../types/types'
import { apiSlice, imagesApiSlice } from '../api/apiSlice'

export const miscApiSlice = imagesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadImage: builder.mutation<ImageUploadResponse, FormData>({
      query: (payload) => ({
        url: `/image/upload`,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(error as string)
        }
      },
    }),
  }),
})

export const { useUploadImageMutation } = miscApiSlice
