/* eslint-disable @typescript-eslint/no-floating-promises */
import { getErrorMessage } from '../../helpers'
import { apiSlice } from '../api/apiSlice'
import {
  addPermission,
  addRole,
  createTeamMember,
  deactivateActivateMember,
  modifyPermission,
  modifyRole,
  modifyTeamMember,
  setFetchingUser,
  setPermissions,
  setRoles,
  setTeamMembers,
  setUser,
} from './user.reducer'
import {
  type UserPayload,
  type CurrentUserResponse,
  type UpdateUserPayload,
  type Role,
  type Permission,
  type AdminResponse,
  type TeamMemberPayload,
  type RolesPayload,
  type PermissionsPayload,
  type CreateTeamMemberResponse,
  type CreatePermissionResponse,
  type UpdateTeamMemberPayload,
  type ErrorType,
  UpdateAvailableLocationsPayload,
  UpdateAvailableLocationsSuccess,
  AvailableLocationsResponse,
  AddeAvailableLocationsSuccess,
  AddAvailableLocationsPayload,
  WalletAmountsResponse,
  AddWalletAmountResponse,
} from './user.type'
import { toast } from 'react-toastify'

interface QueryParams {
  skip: number
  limit: number
}

interface UserResponse {
  data: CurrentUserResponse[]
}

export const userApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAllUsers: builder.query<UserResponse, QueryParams>({
      query: ({ limit, skip }) => ({
        url: `/users?skip=${skip ?? 0}&limit=${limit ?? 20}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'USER', id: 'LIST' }],
    }),
    getUser: builder.query<CurrentUserResponse, { id: number }>({
      query: ({ id }) => ({
        url: `/users/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: CurrentUserResponse }) =>
        response.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          dispatch(setFetchingUser(true))
          const { data } = await queryFulfilled
          dispatch(setUser(data))
          dispatch(setFetchingUser(false))
        } catch (error) {
          dispatch(setFetchingUser(false))
        }
      },
    }),
    createUser: builder.mutation<CurrentUserResponse, UserPayload>({
      query: (payload) => ({
        url: `/users`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'USER', id: 'LIST' }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(setFetchingUser(true))
          toast.success('User added successfully.')
          dispatch(setFetchingUser(false))
        } catch (error) {
          dispatch(setFetchingUser(false))
        }
      },
    }),
    updateUser: builder.mutation<
      CurrentUserResponse,
      { body: UpdateUserPayload; id: number }
    >({
      query: (payload) => ({
        url: `/users/${payload.id}`,
        method: 'PUT',
        body: payload.body,
      }),
      invalidatesTags: [{ type: 'USER', id: 'LIST' }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          toast.success('User updated successfully.')
        } catch (error) {
          console.log(error)
        }
      },
    }),
    activateUser: builder.mutation<null, { id: number; active: boolean }>({
      query: ({ id, active }) => ({
        url: `/users/activate/${id}`,
        method: 'PUT',
        body: { active },
      }),
      invalidatesTags: [{ type: 'USER', id: 'LIST' }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          toast.success('User updated successfully.')
        } catch (error) {
          // console.log(error)
        }
      },
    }),
    deleteUser: builder.mutation<null, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'USER', id: 'LIST' }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(setFetchingUser(true))
          toast.success('User deleted successfully.')
          dispatch(setFetchingUser(false))
        } catch (error) {
          dispatch(setFetchingUser(false))
        }
      },
    }),
    getTeamMembers: builder.query<AdminResponse, QueryParams>({
      query: () => ({
        url: `/admin`,
        method: 'GET',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setTeamMembers(data.data))
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    inviteTeamMember: builder.mutation<
      CreateTeamMemberResponse,
      TeamMemberPayload
    >({
      query: (payload) => ({
        url: `/admin/invite-admin`,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(createTeamMember(data.data.user))
          toast.success('Team member invited successfully.')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    editTeamMember: builder.mutation<
      CreateTeamMemberResponse,
      { payload: UpdateTeamMemberPayload; id: string }
    >({
      query: ({ payload, id }) => ({
        url: `/admin/edit/${id}`,
        method: 'PATCH',
        body: { ...payload, email: undefined },
      }),
      transformResponse: (response: CreateTeamMemberResponse) => response,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(modifyTeamMember(data.data))
          toast.success('Team member updated successfully.')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    deactivateActivateTeamMember: builder.mutation<
      CreateTeamMemberResponse,
      { payload: UpdateTeamMemberPayload; id: string }
    >({
      query: ({ payload, id }) => ({
        url: `/admin/updateadmin/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      transformResponse: (response: CreateTeamMemberResponse) => response,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            deactivateActivateMember({
              id: arg.id,
              deleted: arg.payload.deleted,
            }),
          )
          toast.success(
            `Team member ${
              arg.payload.deleted ? 'deactivated' : 'activated'
            } successfully.`,
          )
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    getRoles: builder.query<Role[], QueryParams>({
      query: ({ limit, skip }) => ({
        url: `/role?skip=${skip ?? 0}&limit=${limit ?? 20}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: Role[] }) => response.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setRoles(data))
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    createRole: builder.mutation<Role, RolesPayload>({
      query: (payload) => ({
        url: `/role`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: { data: Role }) => response.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(addRole(data))
          toast.success('Role created successfully.')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    editRole: builder.mutation<Role, { payload: RolesPayload; id: string }>({
      query: ({ payload, id }) => ({
        url: `/role/${id}`,
        method: 'PUT',
        body: payload,
      }),
      transformResponse: (response: { data: Role }) => response.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(modifyRole(data))
          toast.success('Role updated successfully.')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    getPermissions: builder.query<Permission[], QueryParams>({
      query: ({ limit, skip }) => ({
        url: `/permission?skip=${skip ?? 0}&limit=${limit ?? 20}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: Permission[] }) => response.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setPermissions(data))
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    createPermission: builder.mutation<
      CreatePermissionResponse,
      PermissionsPayload
    >({
      query: (payload) => ({
        url: `/permission`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: { data: CreatePermissionResponse }) =>
        response.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addPermission(data))
          toast.success('Permission created successfully.')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    editPermission: builder.mutation<
      CreatePermissionResponse,
      { payload: PermissionsPayload; id: string }
    >({
      query: ({ payload, id }) => ({
        url: `/permission/${id}`,
        method: 'PUT',
        body: payload,
      }),
      transformResponse: (response: { data: CreatePermissionResponse }) =>
        response.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(modifyPermission(data))
          toast.success('Permission updated successfully.')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    addAvailableLocations: builder.mutation<
      AddeAvailableLocationsSuccess,
      AddAvailableLocationsPayload
    >({
      query: (payload) => ({
        url: `/system-settings/available-locations`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled
          toast.success(response.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    updateAvailableLocations: builder.mutation<
      UpdateAvailableLocationsSuccess,
      UpdateAvailableLocationsPayload
    >({
      query: (payload) => ({
        url: `/system-settings/available-locations`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled
          toast.success(response.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    fetchAvailableLocations: builder.query<AvailableLocationsResponse, void>({
      query: () => ({
        url: `/system-settings/available-locations`,
        method: 'GET',
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    fetchWalletAmounts: builder.query<WalletAmountsResponse, void>({
      query: () => ({
        url: `/admin/fixed-deposit-amount`,
        method: 'GET',
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    addWalletAmount: builder.mutation<
      AddWalletAmountResponse,
      { amount: string }
    >({
      query: (payload) => ({
        url: `/admin/fixed-deposit-amount`,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          toast.success(data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
  }),
})

export const {
  useGetAllUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useActivateUserMutation,
  useGetRolesQuery,
  useGetPermissionsQuery,
  useGetTeamMembersQuery,
  useInviteTeamMemberMutation,
  useCreatePermissionMutation,
  useCreateRoleMutation,
  useEditTeamMemberMutation,
  useEditRoleMutation,
  useEditPermissionMutation,
  useDeactivateActivateTeamMemberMutation,
  useFetchAvailableLocationsQuery,
  useLazyFetchAvailableLocationsQuery,
  useUpdateAvailableLocationsMutation,
  useAddAvailableLocationsMutation,
  useFetchWalletAmountsQuery,
  useAddWalletAmountMutation,
} = userApiSlice
