import React, { useEffect, useState } from 'react'
import {
  useAddProductsToListMutation,
  useGetListProductsQuery,
  useUpdateProductsInListMutation,
} from '../../../redux-toolkits/lists/list.slice'
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from '../../../components/typography/PageHeader'
import { Button } from '../../../components/button'
import Spinner from '../../../components/spinner/Spinner'
import Icon from '../../../components/icon'
import { Product } from '../../../types/types'
import { ProductItem } from '../../../redux-toolkits/lists/list.types'
import { useModalControl } from '../../../hooks/useModalControl'
import { SelectProductModal } from '../../../components/modal/app_modals/selectProductModal'
import { removeElementAtIndex } from '../../../helpers'
import { AddNewCard } from '../../../components/cards/AddNewCard'

export const ManageListProducts = () => {
  const [products, setProducts] = useState<Product[]>([])

  const { id } = useParams<{ id: string }>()

  const navigate = useNavigate()

  const { setModalOpen, modal, handleModalOpen, modalOpen } = useModalControl()

  const {
    data: listProducts,
    isFetching: fetchingList,
    refetch,
  } = useGetListProductsQuery(id || '')

  const [updateProductsInList, { isLoading: addingToList }] =
    useUpdateProductsInListMutation()

  const [addProductsToList, { isLoading: addingNewToList }] =
    useAddProductsToListMutation()

  useEffect(() => {
    if (listProducts?.data?.products) {
      setProducts(listProducts.data.products)
    }
  }, [listProducts?.data?.products])

  return fetchingList ? (
    <Spinner fullScreen={false} />
  ) : (
    <div>
      <PageHeader title={`Manage List Products`} />

      <div className="flex items-center gap-4 justify-end">
        <Button
          type="button"
          onClick={() => {
            navigate('/lists/curated')
          }}
          label="Cancel"
          size="medium"
          className="!px-10"
        />

        <Button
          type="submit"
          label="Save"
          primary
          disabled={addingToList || addingNewToList}
          loading={addingToList || addingNewToList}
          size="medium"
          className="!px-10"
          onClick={() => {
            listProducts?.data?.products
              ? updateProductsInList({
                  productIds: products.map((product) => product._id),
                  listId: id ?? '',
                })?.then((resp) => {
                  resp.data && refetch()
                })
              : addProductsToList({
                  productIds: products.map((product) => product._id),
                  listId: id ?? '',
                })?.then((resp) => {
                  resp.data && refetch()
                })
          }}
        />
      </div>

      <div className="mt-10">
        {products && (
          <div
            className="
                  grid grid-flow-row 
                  grid-cols-[repeat(auto-fit,_minmax(200px,200px))]
                  gap-4 
                  "
          >
            {products.map((product, idx) => (
              <div
                className="
                        flex flex-col gap-2 font-normal
                        "
                key={product._id}
              >
                <div className="flex items-center justify-between gap-4">
                  <span className="black text-sm font-medium">
                    {product.name}
                  </span>
                  <button
                    className="p-2 bg-danger/10 rounded-full"
                    onClick={() => {
                      const productsCopy = JSON.parse(JSON.stringify(products))
                      setProducts(removeElementAtIndex(productsCopy, idx))
                    }}
                  >
                    <Icon
                      className="text-danger"
                      width={16}
                      height={16}
                      id="bin"
                    />
                  </button>
                </div>
                <div className="border flex-1 rounded-lg">
                  <img
                    src={product.images[0]}
                    alt={product.name}
                    className="
                    object-cover rounded-lg
                    overflow-hidden h-full w-full
                    "
                  />
                </div>
              </div>
            ))}
            <AddNewCard
              className=""
              onClick={() => {
                handleModalOpen('selectProductModal')
              }}
            />
          </div>
        )}
      </div>

      <SelectProductModal
        open={modal === 'selectProductModal' && modalOpen}
        setOpen={setModalOpen}
        onCloseCallback={() => {}}
        onSuccessCallback={(selected) => {
          if (!products.map((product) => product._id).includes(selected._id)) {
            setProducts([...products, selected])
          }
        }}
      />
    </div>
  )
}
