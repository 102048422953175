import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { authSelector } from '../redux-toolkits/auth/auth.selector'
import { useAppSelector } from '../hooks'

const NoneProtectedRoute = (): JSX.Element => {
  const { signedIn } = useAppSelector(authSelector)

  return !(signedIn as boolean) ? (
    <Outlet />
  ) : (
    <Navigate to="/dashboard" replace />
  )
}

export default NoneProtectedRoute
