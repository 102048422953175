import { toast } from 'react-toastify'
import { CURRENT_PAGE_NUMBER, NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { Article, CreateArticle } from '../../types/types'
import { apiSlice } from '../api/apiSlice'
import {
  type CreateListBody,
  type CreateListResponseData,
  type ListsResponseData,
  type ListCategoryResponseData,
  type ListArticlesResponse,
  type SingleListArticleResponse,
  type ListCommentsResponseData,
  type ListResponseData,
  type ListProductsResponseData,
  EditedListArticleResponse,
  DeleteListArticleResponse,
} from './list.types'
import { getErrorMessage } from '../../helpers'
import { ErrorType } from '../user/user.type'

interface QueryParams {
  skip?: number
  limit?: number
  status?: string
  startDate?: string
  endDate?: string
}

export const listsApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    fetchLists: builder.query<ListsResponseData, QueryParams>({
      query: ({ limit, skip }) => ({
        url: `/lists?page=${skip ?? 1}&limit=${
          limit ?? NUMBER_OF_ITEMS_PER_PAGE
        }
      `,
        method: 'GET',
      }),
      transformResponse: (data: ListsResponseData) => {
        return data
      },
    }),

    fetchListCategories: builder.query<ListCategoryResponseData, QueryParams>({
      query: ({ limit, skip }) => ({
        url: `/items/categories?skip=${skip ?? 0}&limit=${
          limit ?? NUMBER_OF_ITEMS_PER_PAGE
        }
      `,
        method: 'GET',
      }),
      transformResponse: (data: ListCategoryResponseData) => {
        return data
      },
    }),

    addProductsToList: builder.mutation<
      ListCategoryResponseData,
      { productIds: string[]; listId: string }
    >({
      query: ({ productIds, listId }) => ({
        url: `/lists/${listId}/items`,
        method: 'POST',
        body: { productIds },
      }),
      transformResponse: (data: ListCategoryResponseData) => {
        return data
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          toast.success('Products added successfully')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),

    updateProductsInList: builder.mutation<
      ListCategoryResponseData,
      { productIds: string[]; listId: string }
    >({
      query: ({ productIds, listId }) => ({
        url: `/lists/${listId}/items`,
        method: 'PUT',
        body: { productIds },
      }),
      transformResponse: (data: ListCategoryResponseData) => {
        return data
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),

    getListProducts: builder.query<ListProductsResponseData, string>({
      query: (listId) => ({
        url: `/lists/${listId}/items`,
        method: 'GET',
      }),
      transformResponse: (data: ListProductsResponseData) => {
        return data
      },
    }),

    createList: builder.mutation<CreateListResponseData, CreateListBody>({
      query: (body) => ({
        url: `/lists`,
        method: 'POST',
        body,
      }),
      transformResponse: (data: CreateListResponseData) => {
        return data
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          toast.success(data.message)
        } catch (error) {
          toast.error('Something went wrong, please try again.')
        }
      },
    }),

    editList: builder.mutation<
      CreateListResponseData,
      { id: string; body: CreateListBody }
    >({
      query: ({ id, body }) => ({
        url: `/lists/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (data: CreateListResponseData) => {
        return data
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          toast.success(data.message)
        } catch (error) {
          toast.error('Something went wrong, please try again.')
        }
      },
    }),

    pinUnpinList: builder.mutation<
      CreateListResponseData,
      { id: string; body: { pinned: boolean | string } }
    >({
      query: ({ id, body }) => ({
        url: `/lists/${id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (data: CreateListResponseData) => {
        return data
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          toast.success(data.message)
        } catch (error) {
          toast.error('Something went wrong, please try again.')
        }
      },
    }),

    moveList: builder.mutation<
      { data: null; message: string; success: boolean },
      { id: string; categoryId: string; newPosition: number }
    >({
      query: ({ id, categoryId, newPosition }) => ({
        url: `/lists/move/${id}/${categoryId}`,
        method: 'PATCH',
        body: {
          toIndex: newPosition,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          toast.success(data.message)
        } catch (error) {
          toast.error('Something went wrong, please try again.')
        }
      },
    }),

    publishUnpublishList: builder.mutation<
      { data: null; message: string; success: boolean },
      { type: 'publish' | 'unpublish'; id: string; categoryId: string }
    >({
      query: ({ id, categoryId, type }) => ({
        url:
          type === 'publish'
            ? `/lists/publish/${id}/${categoryId}`
            : `/lists/unpublish/${id}/${categoryId}`,
        method: 'PATCH',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          toast.success(data.message)
        } catch (error) {
          toast.error('Something went wrong, please try again.')
        }
      },
    }),

    fetchList: builder.mutation<ListResponseData, string>({
      query: (id) => ({
        url: `/lists/${id}/details`,
        method: 'GET',
      }),
      transformResponse: (data: ListResponseData) => {
        return data
      },
    }),

    deleteList: builder.mutation<{ success: boolean }, string>({
      query: (id) => ({
        url: `/lists/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          toast.success('list deleted successfully.')
        } catch (error) {
          toast.error('Something went wrong, please try again.')
        }
      },
    }),

    fetchListComments: builder.query<
      ListCommentsResponseData,
      { id: string; query: QueryParams }
    >({
      query: ({ id, query: { limit, skip } }) => ({
        url: `/lists/${id}/?page=${skip ?? CURRENT_PAGE_NUMBER}&limit=${
          limit ?? NUMBER_OF_ITEMS_PER_PAGE
        }
        `,
        method: 'GET',
      }),
      transformResponse: (data: ListCommentsResponseData) => {
        return data
      },
    }),
    fetchAllListArticles: builder.query<
      ListArticlesResponse,
      { query: QueryParams }
    >({
      query: ({ query: { limit, skip } }) => ({
        url: `/articles?page=${skip ?? CURRENT_PAGE_NUMBER}&limit=${
          limit ?? NUMBER_OF_ITEMS_PER_PAGE
        }
        `,
        method: 'GET',
      }),
      transformResponse: (data: ListArticlesResponse) => {
        return data
      },
    }),
    fetchSingleListArticle: builder.query<
      SingleListArticleResponse,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/articles/${id}`,
        method: 'GET',
      }),
      transformResponse: (data: SingleListArticleResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {
          toast.error(error as string)
        }
      },
    }),
    createListArticle: builder.mutation<
      SingleListArticleResponse,
      CreateArticle
    >({
      query: (payload) => ({
        url: `/articles`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (data: SingleListArticleResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(error as string)
        }
      },
    }),
    editListArticle: builder.mutation<
      EditedListArticleResponse,
      { id: string; data: CreateArticle }
    >({
      query: ({ id, data }) => ({
        url: `/articles/${id}`,
        method: 'PATCH',
        body: { article: data },
      }),
      transformResponse: (data: EditedListArticleResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(error as string)
        }
      },
    }),
    deleteListArticle: builder.mutation<
      DeleteListArticleResponse,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/articles/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (data: DeleteListArticleResponse) => {
        return data
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success(data.data.message)
        } catch (error) {
          toast.error(error as string)
        }
      },
    }),
  }),
})

export const {
  useEditListMutation,
  useCreateListMutation,
  useFetchListCategoriesQuery,
  useAddProductsToListMutation,
  useUpdateProductsInListMutation,
  useFetchListMutation,
  useDeleteListMutation,
  useFetchListsQuery,
  useLazyFetchListsQuery,
  useFetchListCommentsQuery,
  useFetchAllListArticlesQuery,
  useLazyFetchAllListArticlesQuery,
  useLazyFetchSingleListArticleQuery,
  useCreateListArticleMutation,
  usePinUnpinListMutation,
  useMoveListMutation,
  useGetListProductsQuery,
  useEditListArticleMutation,
  useDeleteListArticleMutation,
  usePublishUnpublishListMutation,
} = listsApiSlice
