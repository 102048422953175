import React, { FC } from 'react'

export const PageHeader: FC<{ title: string; className?: string }> = ({
  title,
  className,
}) => {
  return (
    <span
      className={`
        font-recoleta-bold
        text-2xl
        block
        mb-10
        ${className}
        `}
    >
      {title}
    </span>
  )
}
