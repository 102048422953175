import React from 'react'
import Icon from '../icon'

export const Logo = (): JSX.Element => {
  return (
    <div className=" relative w-[70px] text-primary">
      <Icon id="good-logo" width={70} height={20} />
    </div>
  )
}
